import React, { useEffect } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Layout from './component/Layout/Layout';
import Home from './Home/Home';
import Login from './component/Auth/Login';
import { LanguageProvider } from './Context/Context'; // استيراد LanguageProvider من ملف Context.js
import Settings from './component/Settings/Settings';
import Emails_sending from './component/Settings/Emails_sending';
import Services from './component/Settings/services';
import Project from './component/Settings/projects';
import Admin from './component/Settings/Admin';
import Tasks from './component/Settings/Tasks';
import Othertasks from './component/Settings/Othertasks';
import Permissions from './component/Settings/permissions';
import Teamwork from './component/Settings/teamwork';
import Daily_report from './component/Settings/daily_report';
import Olddaily_report from './component/Settings/Olddaily_report';
import Email from './component/Navbar/Email';
import Future from './component/Settings/future';
import Projectt from './component/Settings/Project';
import Wait from './component/Settings/Wait';
import Current_myprojects from './component/Settings/current_myprojects';
import TastProject from './component/Settings/Tasksproject';
// import AddTask from './component/Settings/AddTask';
import DataTable, { SortOrder } from 'react-data-table-component';
import AddTaskProject from './component/AddTask';
import { MyContextProvider } from './component/Context/Context';
import { ToastContainer } from 'react-toastify';

import { Toaster } from 'react-hot-toast';
import ProtucedRout from './component/ProtuctedRout/ProtucedRout';
import ModelPop from './component/Settings/ModelPop';
import Datetable from './component/Settings/Datetable';
import Ubdateuser from './component/Settings/Ubdateuser';
import { getrteToken, messaging } from './firebase';
import { onMessage } from 'firebase/messaging';
import ReportWeek from './component/Report/ReportWeek';
import Monthly_report from './component/Settings/Monthly_report';
import YourComponent from './component/Settings/YourComponent';

let routes = createBrowserRouter([



  { path: '/', element: <Layout />, children: [
    { index:true, element: <ProtucedRout><Home /></ProtucedRout> },
    { path: "Settings", element: <Settings /> },
    { path: "Emails_sending", element: <Emails_sending /> },
    { path: "Services", element: <Services /> },
    { path: "Project", element: <Project /> },
    { path: "admin", element: <Admin /> },
    { path: "Tasks", element: <Tasks /> },
    { path: "Othertasks", element: <Othertasks /> },
    { path: "permissions", element: <Permissions /> },
    { path: "Teamwork", element: <Teamwork /> },
    { path: "YourComponent", element: <YourComponent /> },
    { path: "daily_report", element: <ReportWeek /> },
    { path: "Monthly_report", element: <Monthly_report /> },
    { path: "Olddaily_report", element: <Olddaily_report /> },
    { path: "email", element: <Email /> },
    { path: "Future", element: <Future /> },
    { path: "projectt", element: <Projectt /> },
    { path: "wait", element: <Wait /> },
    { path: "TastProject", element: <TastProject /> },
    // { path: "AddTask", element: <AddTask /> },
    { path: "AddTaskProject/:id", element: <AddTaskProject /> },
    { path: "current_myprojects", element: <Current_myprojects /> },
    { path: "Ubdateuser", element: <Ubdateuser /> },
    { path: "Datetable", element: <Datetable /> },
    { path:"login", element: <Login /> },
  ]


  
}
]);

function App() {

  useEffect(()=>{
    getrteToken()
    onMessage(messaging , (payload)=>{
console.log(payload, "Ahjmmed" );
    })
    },[])
  
  const colums = [
    {
      name:"Name",
      selector:row=>row.name,
      sortable:false
    },
    {
      name:"Email",
      selector:row=>row.email,
      sortable:true
    },
    {
      name:"Age",
      selector:row=>row.age,
      sortable:true
    },
  ]
  const data = [
    {
      id:1,
      name:"Ahmed",
      email:"kosdfhgv ",
      age:"20"
    },
    {
      id:1,
      name:"mahmed",
      email:"kosdfhgv ",
      age:"20"
    },
    {
      id:1,
      name:"omer",
      email:"kosdfhgv ",
      age:"20"
    },
    {
      id:1,
      name:"ashfet",
      email:"kosdfhgv ",
      age:"20"
    },
    {
      id:1,
      name:"Ahmed",
      email:"kosdfhgv ",
      age:"20"
    },
  ]
 const [records, setrecords] = React.useState(data);

 function handleFilter(event){
  const newData = data.filter(row=>{
    return row.name.toLocaleUpperCase().includes(event.target.value.toLocaleUpperCase())
  })
  setrecords(newData)
 }

  return (
    <div className='container mx-auto'>
      |<MyContextProvider>
      <LanguageProvider>
      <Toaster />
      <ToastContainer/>
  <RouterProvider router={routes}></RouterProvider>
</LanguageProvider>
      </MyContextProvider>
    
    
    
    </div>
  );
}

export default App;
