import axios from 'axios';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';

export default function DeleteAll() {
  let { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);

  const handleDelete = async () => {
    const token = localStorage.getItem('token');
    setIsLoading(true);
    try {
      let response = await axios.post(
        `https://api.bokaramstore.com/api/projects/delete/${id}`,
        { _method: "delete" },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      console.log(response);
      if (response.data.message === "success") {
        toast.success("Project deleted successfully");
      }
    } catch (error) {
      console.error('Error deleting project:', error);
      toast.error('Failed to delete project');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div>
      <button 
        onClick={handleDelete} 
        className='btn bg-danger px-4 text-white mt-4 mb-4'
        disabled={isLoading}
      >
        {isLoading ? 'جارٍ الحذف...' : 'نعم'}
      </button>
    </div>
  );
}
