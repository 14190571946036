import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import toast from "react-hot-toast";
import Roules from "./Roules";
import Model from "./Model";

export default function Permissions() {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCheckChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };
  const colums = [
    {
      name: " الأسم",
      selector: (row) => row.name,
      sortable: false,
    },
    {
      name: "المسئول ",
      selector: (row) => row.email,
      sortable: true,
    },

    {
      name: "	التاريخ",
      selector: (row) => row.datea,
      sortable: true,
    },
    {
      name: " التواجد فى المشاريع",
      selector: (row) => row.updaute,
      sortable: true,
    },

    {
      name: "الحاله",
      cell: (row) => (
        <div className="w-50">
          <DropdownButton
            id="dropdown-basic-button"
            className="w-50"
            title=" الحاله"
          >
            <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
            <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
            <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
          </DropdownButton>
        </div>
      ),
      sortable: true,
      selector: (row) => row.age,
    },
    {
      name: "العمليات",
      selector: (row) => row.Processes,
      sortable: true,
      cell: (row) => (
        <div className="w-50">
          <DropdownButton
            id="dropdown-basic-button"
            title="العمليات"
            className=""
          >
            <Dropdown.Item to="#">
              <div className="d-flex mx-3 pt-1">
                <p>
                  <i className="fas fa-edit"></i>
                </p>
                <p className="mx-2">التحديث</p>
              </div>
            </Dropdown.Item>
            <Dropdown.Item to="#">
              <div className="d-flex mx-3 pt-1 pb-2">
                <p className="mx-2">
                  <i className="fa-solid fa-x"></i>
                </p>
                <p>حذف</p>
              </div>
            </Dropdown.Item>
          </DropdownButton>
        </div>
      ),
    },
  ];
  const data = [
    {
      id: 1,
      name: "Ahmrf",
      email: "املكى ",
      age: "20",
      row: "true",
      datea: "1/1/2002",
      updaute: "1/1/2002",
      Processes: "Process",
    },
    {
      id: 1,
      name: "Ahmrf",
      email: "املكى ",
      age: "20",
      row: "true",
      datea: "1/1/2002",
      updaute: "1/1/2002",
      start: "1/1/202",
      Processes: "Process",
    },
    {
      id: 1,
      name: "Ahmrf",
      email: "املكى ",
      age: "20",
      row: "true",
      datea: "1/1/2002",
      updaute: "1/1/2002",
      start: "1/1/202",
      Processes: "Process",
    },
    {
      id: 1,
      name: "Ahmrf",
      email: "املكى ",
      age: "20",
      row: "true",
      datea: "1/1/2002",
      updaute: "1/1/2002",
      start: "1/1/202",
      Processes: "Process",
    },
  ];




  const onSubmit = async (values) => {
    try {

        const token = localStorage.getItem('token'); // Get token from local storage
        const formData = new FormData(); // Create FormData object
        formData.append('name', values.name); // Append form fields
        formData.append('email', values.email); // Append email
    
        // Logging formData for debugging
        // console.log(formData, 'Form Data:');

        // Sending form data to the API
        const response = await axios.post(`https://api.bokaramstore.com/api/employees/create`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Set content type for FormData
                'Authorization': `Bearer ${token}` // Include token in request headers
            },
        });

        // console.log('Data sent successfully!');
        // console.log('Response:', response.data.message); // Print the response

        if (response.data.message === 'success') {
            toast.success(response.data.message);
            toast.success('Successfully toasted!')
        } else {
            toast.error(response.data.message);
        }

        // إعادة عرض زر "Submit"

    } catch (error) {
        // console.error('Error:', error.response.data.errors); // Print error message and data
    }
};




  const [records, setrecords] = React.useState(data);

  function handleFilter(event) {
    const newData = data.filter((row) => {
      return row.name
        .toLocaleUpperCase()
        .includes(event.target.value.toLocaleUpperCase());
    });
    setrecords(newData);
  }
  return (
    <>
      <Link className="fw-bold text-dark" to={"/"}>
        الرئسيه
      </Link>
      <Link className="text-muted mx-2 text-decoration-none mx-4">
        الأقسام{" "}
      </Link>

      <div className="mt-5 d-flex">
        <p className="mx-2">
          <i className="fa fa-cog" aria-hidden="true"></i>
        </p>
        <p className="text-muted"> الأقسام </p>
      </div>

      <div className="mt-4 d-flex">
        {/* <button className="btn btn-danger px-3">
          {" "}
          <i className="fa-solid fa-xmark"></i> حذف{" "}
        </button> */}
        <button
          className="btn bg-color p-1 text-white mx-3"
          onClick={handleModalShow}
        >
          {" "}
          <i className="fa-solid fa-plus"></i> اضافه{" "}
        </button>
      </div>

      {/* ----------- start-Table ------------------------*/}
      <div className="col-3">
        <input
          type="search"
          placeholder="search"
          className="text-end form-control bg-gray mt-3 mb-3"
          onChange={handleFilter}
        />
      </div>
  
      <Roules></Roules>

      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-5 pt-3 text-color "> الأضافة</Modal.Title>
        </Modal.Header>
        <Modal.Body>
       <Model></Model>
        </Modal.Body>
        <Modal.Footer className="text-center d-center mb-4 mt-4">
          <Button
            variant="secondary"
            className="px-4 mx-4"
            onClick={handleModalClose}
          >
            إغلاق
          </Button>
          {/* <Button variant="primary" className="px-4" onClick={handleModalClose}>
            حفظ
          </Button> */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
