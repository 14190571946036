import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Modal, Button, DropdownButton, Dropdown } from 'react-bootstrap';
import DataTable from 'react-data-table-component';

export default function Emails_sending() {
  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const handleCheckChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  const handleModalShow = () => {
    setShowModal(true);
  };
  const colums = [
    {
      name:"البريد الألكترونى",
      selector:row=>row.name,
      sortable:false
    },
    {
      name:"سبب المرسلة",
      selector:row=>row.email,
      sortable:true
    },

    {
      name:"	الأسم",
      selector:row=>row.datea,
      sortable:true
    },
    {
      name:"المسمى الوظيفى",
      selector:row=>row.updaute,
      sortable:true
    },
    {
      name:"التخصص",
      selector:row=>row.start,
      sortable:true
    },

    {
      name: "الحاله",
      cell: row => (
    <div className="w-50">

<DropdownButton id="dropdown-basic-button" className="w-50" title=" الحاله">
          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
        </DropdownButton>
    </div>
      ),
      sortable: true,
      selector:row=>row.age,
    },
    {
      name:"العمليات",
      selector:row=>row.Processes,
      sortable:true,
        cell: row => (
    <div className="w-50">

<DropdownButton
                  id="dropdown-basic-button"
                  title="العمليات"
                  className=""
                >
                  <Dropdown.Item to="#">
                    <div className="d-flex mx-3 pt-1">
                      <p>
                        <i className="fas fa-edit"></i>
                      </p>
                      <p className="mx-2">التحديث</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item to="#">
                    <div className="d-flex mx-3 pt-1 pb-2">
                      <p className="mx-2">
                        <i className="fa-solid fa-x"></i>
                      </p>
                      <p>حذف</p>
                    </div>
                  </Dropdown.Item>
                </DropdownButton>
    </div>
      ),
    },
  ]
  const data = [
   
      {
        id:1,
        name:"Ahmrf",
        email:"املكى ",
        age:"20",
        row:"true",
        datea:"1/1/2002",
        updaute:"1/1/2002",
        start:"1/1/202",
        Processes:"Process"
      },
      {
        id:1,
        name:"Ahmrf",
        email:"املكى ",
        age:"20",
        row:"true",
        datea:"1/1/2002",
        updaute:"1/1/2002",
        start:"1/1/202",
        Processes:"Process"
      },
      {
        id:1,
        name:"Ahmrf",
        email:"املكى ",
        age:"20",
        row:"true",
        datea:"1/1/2002",
        updaute:"1/1/2002",
        start:"1/1/202",
        Processes:"Process"
      },
      {
        id:1,
        name:"Ahmrf",
        email:"املكى ",
        age:"20",
        row:"true",
        datea:"1/1/2002",
        updaute:"1/1/2002",
        start:"1/1/202",
        Processes:"Process"
      },

  ]
 const [records, setrecords] = React.useState(data);

 function handleFilter(event){
  const newData = data.filter(row=>{
    return row.name.toLocaleUpperCase().includes(event.target.value.toLocaleUpperCase())
  })
  setrecords(newData)
 }
  return (
    <>
      <Link className='fw-bold text-dark' to={"/"}>الرئسيه</Link>
      <Link className='text-muted mx-2 text-decoration-none mx-4'> قائمة ايميلات المرسلة </Link>

      <div className="mt-5 d-flex">
        <p className='mx-2'><i className="fa fa-cog" aria-hidden="true"></i></p>
        <p className='text-muted'>قائمة ايميلات المرسلة</p>
      </div>

      <div className="mt-4 d-flex">
        <button className='btn btn-danger px-3'> <i className="fa-solid fa-xmark"></i>   حذف </button>
        <button className='btn bg-color p-1 text-white mx-3' onClick={handleModalShow}> <i className="fa-solid fa-plus"></i>  اضافه </button>
      </div>




      {/* ----------- start-Table ------------------------*/}
      <div className="col-3">

      <input type="search" placeholder="search"   className='text-end form-control bg-gray mt-3 mb-3' onChange={handleFilter}/>
      </div>
      <DataTable
      columns={colums}
      data={records}
      selectableRows
      
      pagination="auto"
      ></DataTable>
      {/* ----------- end-Table ------------------------*/}

      


      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className='mx-5 pt-3 text-color '> إضافة بريد الكترونى</Modal.Title>
        </Modal.Header>
        <Modal.Body>
         <div className="container">
          <div className="row mx-4">
          <div className=" col-4 mt-4">
<label className='w-100 '>     
البريد الالكترونى 
</label>
<input type="email" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='     
البريد الالكترونى
'/>
</div>
          <div className=" col-4 mt-4">
<label className='w-100 '>     
الموظف 
</label>
<select className="w-75 bg-gray mt-2" aria-label="Default select example">
  <option selected>Active (TeamWork)</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
</div>
          <div className=" col-4 mt-4">
<label className='w-100 '>     

سبب المرسالة
</label>
<select className="w-75 bg-gray mt-2 w-75" aria-label="Default select example">
  <option selected>في حالة المهام علي العمليات</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
</div>
            <div className="col-4"></div>
            <div className="col-4"></div>
          </div>
         </div>
        </Modal.Body>
        <Modal.Footer className='text-center d-center mb-4 mt-4'>
          <Button variant="secondary" className='px-4 mx-4' onClick={handleModalClose}>
            إغلاق
          </Button>
          <Button variant="primary" className='px-4' onClick={handleModalClose}>
            حفظ
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
