import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import ReactDatePicker from "react-datepicker";
import DataTable from "react-data-table-component";

export default function Wait() {
  const [showModal, setShowModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const handleShowModal = () => setShowModal(true);
  const handleCloseModal = () => setShowModal(false);
  const [showSendModal, setShowSendModal] = useState(false);
  const [showInboxModal, setShowInboxModal] = useState(false);
  const [showOutboxModal, setShowOutboxModal] = useState(false);

  const handleCloseSend = () => setShowSendModal(false);
  const handleCloseInbox = () => setShowInboxModal(false);
  const handleCloseOutbox = () => setShowOutboxModal(false);
  const [isChecked, setIsChecked] = useState(false);

  const handleCheckChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
  };
  const colums = [
    {
      name:"الكود",
      selector:row=>row.name,
      sortable:false
    },
    {
      name:"الأسم",
      selector:row=>row.email,
      sortable:true
    },
    {
      name: "الحاله",
      cell: row => (
    <div className="w-50">

<DropdownButton id="dropdown-basic-button" className="w-50" title=" الحاله">
          <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
          <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
          <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
        </DropdownButton>
    </div>
      ),
      sortable: true,
      selector:row=>row.age,
    },
    {
      name:"التاريخ",
      selector:row=>row.datea,
      sortable:true
    },
    {
      name:"التحديث",
      selector:row=>row.updaute,
      sortable:true
    },
    {
      name:"البداية",
      selector:row=>row.start,
      sortable:true
    },
    {
      name:"المسئول",
      selector:row=>row.Administrator,
      sortable:true
    },
    {
      name:"المدير",
      selector:row=>row.manager,
      sortable:true
    },
    {
      name:"العمليات",
      selector:row=>row.Processes,
      sortable:true,
        cell: row => (
    <div className="w-50">

<DropdownButton
                  id="dropdown-basic-button"
                  title="العمليات"
                  className=""
                >
                  <Dropdown.Item to="#">
                    <div className="d-flex mx-3 pt-1">
                      <p>
                        <i className="fas fa-edit"></i>
                      </p>
                      <p className="mx-2">التحديث</p>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item to="#">
                    <div className="d-flex mx-3 pt-1 pb-2">
                      <p className="mx-2">
                        <i className="fa-solid fa-x"></i>
                      </p>
                      <p>حذف</p>
                    </div>
                  </Dropdown.Item>
                </DropdownButton>
    </div>
      ),
    },
  ]
  const data = [
   
      {
        id:1,
        name:"Ahmrf",
        email:"املكى ",
        age:"20",
        row:"true",
        datea:"1/1/2002",
        updaute:"1/1/2002",
        start:"1/1/202",
        Administrator:"Active",
        manager:"Active",
        Processes:"Process"
      },
      {
        id:1,
        name:"mahamed",
        email:"املكى ",
        age:"20",
        row:"true",
        datea:"1/1/2002",
        updaute:"1/1/2002",
        start:"1/1/202",
        Administrator:"Active",
        manager:"Active",
        Processes:"Process"
      },
      {
        id:1,
        name:"Ashref",
        email:"املكى ",
        age:"20",
        row:"true",
        datea:"1/1/2002",
        updaute:"1/1/2002",
        start:"1/1/202",
        Administrator:"Active",
        manager:"Active",
        Processes:"Process"
      },
      {
        id:1,
        name:"Elnagar",
        email:"املكى ",
        age:"20",
        row:"true",
        datea:"1/1/2002",
        updaute:"1/1/2002",
        start:"1/1/202",
        Administrator:"Active",
        manager:"Active",
        Processes:"Process"
      },
      {
        id:1,
        name:"mohsen",
        email:"املكى ",
        age:"20",
        row:"true",
        datea:"1/1/2002",
        updaute:"1/1/2002",
        start:"1/1/202",
        Administrator:"Active",
        manager:"Active",
        Processes:"Process"
      },
 
  ]
 const [records, setrecords] = React.useState(data);

 function handleFilter(event){
  const newData = data.filter(row=>{
    return row.name.toLocaleUpperCase().includes(event.target.value.toLocaleUpperCase())
  })
  setrecords(newData)
 }

  return (
    <>




      <Link className="fw-bold text-dark" to={"/"}>
        الرئيسية
      </Link>
      <Link className="text-muted mx-2 text-decoration-none mx-4">
        {" "}
        المشاريع المنتظره
      </Link>



      <div className="mt-5 d-flex">
        <p className="mx-2">
          <i className="fa fa-cog" aria-hidden="true"></i>
        </p>
        <p className="text-muted"> المشاريع  المنتظره</p>
      </div>

      <div className="d-flex mt-5">
        <button className="btn btn-danger px-3">
          {" "}
          <i className="fa fa-xmark"></i> حذف{" "}
        </button>
        <div className="d-flex mx-3 ">
          <button
            className="btn bg-color p-1 px-3 text-white"
            onClick={handleShowModal}
          >
            الاضافه{" "}
            <span>
              <i className="fa-solid fa-plus"></i>
            </span>
          </button>
        </div>
      </div>

      {/* ----------- start-Table ------------------------*/}
      <div className="col-3">

      <input type="search" placeholder="search"   className='text-end form-control bg-gray mt-3 mb-3' onChange={handleFilter}/>
      </div>
      <DataTable
      columns={colums}
      data={records}
      selectableRows
      
      pagination="auto"
      ></DataTable>
      {/* ----------- end-Table ------------------------*/}

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton className="bg-color text-white">
          {/* <Modal.Title>عنوان النافذة</Modal.Title> */}
          <div className="bg-color w-100">
            <div className="p-1">
              <h4 className="text-white mx-3">اضافة مشروع</h4>
            </div>
          </div>
        </Modal.Header>
        <Modal.Body>
          <div className="container mx-3">
            <div className="row">
              <div className=" col-3  mt-3">
                <label className="w-100">اسم المشروع</label>
                <input
                  type="text"
                  name=""
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray "
                  placeholder=" اسم المشروع
"
                />
              </div>
              <div className="col-3  mt-3">
                <label className="w-100 mx-2">تاريخ البداية</label>
                <ReactDatePicker
                  className="form-control border-w mt-2 w-75 d-center bg-gray"
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="تاريخ البداية"
                />
              </div>

              <div className=" col-3  mt-3 ">
                <label className="w-100 "> تاريخ الأنتهاء</label>
                <ReactDatePicker
                  className="form-control border-w mt-2 w-75 d-center bg-gray"
                  selected={selectedDate}
                  onChange={(date) => setSelectedDate(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="تاريخ البداية"
                />
              </div>

              <div className="col-3 m  mt-3">
                <label className=" m">مدير المشروع</label>
                <select
                  className=" w-75 bg-gray w-25 mt-2"
                  aria-label="Default select example"
                >
                  <option selected>بدون</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>

              <div className="col-12 mt-3">
                <label className=" text-center "> تفاصيل المشروع</label>
                <textarea
                  className="form-control mt-2 w-94 bg-gray border border-2 "
                  style={{ height: "100px" }}
                  placeholder="تفاصيل المشروع"
                />
              </div>
              <div className="col-3 mt-4">
                <label className=" m">حالة التنفيذ</label>
                <select
                  className=" w-75 bg-gray w-25 mt-2"
                  aria-label="Default select example"
                >
                  <option selected>حالة التنفيذ</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>
              <div className="col-3 mt-4">
                <label className=" m">حالة المشروع</label>
                <select
                  className=" w-75 bg-gray w-25 mt-2"
                  aria-label="Default select example"
                >
                  <option selected> مشروع منتظر</option>
                  <option value="1">One</option>
                  <option value="2">Two</option>
                  <option value="3">Three</option>
                </select>
              </div>


              <div className=" col-3 mt-4">
                <label className="w-100 "> اجمالى ساعات المشروع</label>
                <input
                  type="number"
                  name=""
                  id=""
                  className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"
                  placeholder="      اجمالى ساعات المشروع 
"
                />
              </div>


              <div className=" col-3 mt-4">
<label className='w-100 '>     
اجمالى ايام المشروع
</label>
<input type="number" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='     
اجمالى ايام المشروع
'/>
</div>





<div className='col-3 mt-5'>
<label className=' m'>   
 لوجو المشروع
</label>
<input type="file" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='     
اجمالى ايام المشروع
'/>
</div>
<div className="col-12">

<button className='btn bg-color text-white px-3 p-1 w-2  mt-4 mb-4 '>حفظ البانات</button>
</div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" className="px-4 mb-3 mx-5" onClick={handleCloseModal}>
            إغلاق
          </Button>
          {/* زر لعمل أي عمليات أخرى */}
        </Modal.Footer>
      </Modal>
    </>
  );
}
