import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import UbdateModel from './UbdateModel';
import { Link } from 'react-router-dom';

export default function Roules() {
    const [records, setRecords] = useState([]);
    const [show, setShow] = useState(false);
    const [showw, setShoww] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClosee = () => setShoww(false);
    const handleShoww = () => setShoww(true);

    const columns = [
        {
            name: "المسمى الوظيفي",
            selector: (row) => row.name,
            sortable: false,
        },
        {
            name: "الصلاحيات",
            cell: (row) => <Link className='text-decoration-none' onClick={() => handleRowClickk(row.id)}>تعديل الصلاحية</Link>,
            sortable: true,
        },
        {
            name: "تاريخ الإنشاء",
            selector: (row) =>row.created_at.slice(0, 20),
            sortable: true,
        },
        {
            name: "تاريخ التعديل",
            selector: (row) => row.updated_at.slice(0, 20),
            sortable: true,
        },
        {
            name: "الحالة",
            cell: (row) => (
                <div className="w-75 ">
                    {row.status == 1 ? <div className='bg-color text-center text-white border rounded p-1'>الحساب مفعل</div> :
                        <div className='bg-color text-center text-white border-r rounded'>الحساب غير مفعل</div>
                    }
                </div>
            ),
            sortable: true,
            selector: (row) => row.Processess,
        },
        {
            name: "العمليات",
            cell: (row) => (
                <div className="d-flex">
                    <button className='btn btn-danger btn-sm mx-2 px-1' onClick={() => handleDelete(row)}>حذف</button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://api.bokaramstore.com/api/roles', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                setRecords(response.data.data);
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });
    }, []);

    const handleDelete = (row) => {
        const token = localStorage.getItem('token');
        const id = row.id;
        axios.delete(`https://api.bokaramstore.com/api/roles/delete/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
            .then(response => {
                axios.get('https://api.bokaramstore.com/api/sections', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                    .then(response => {
                        setRecords(response.data.data);
                    })
                    .catch(error => {
                        console.error('Error fetching data:', error);
                    });
            })
            .catch(error => {
                console.error('Error deleting data:', error);
            });
    };

    const handleRowClick = (id) => {
        setSelectedUserId(id);
        handleShow();
    };
    const handleRowClickk = (id) => {
        setSelectedUserId(id);
        handleShoww();
    };

    return (
        <>
            <div className='text-center'>
                <DataTable
                    columns={columns}
                    data={records}
                    selectableRows
                    pagination
                />
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton className='mx-5 pt-3'>
                    <Modal.Title>الإضافة</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* <UodateData userId={selectedUserId} /> */}
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

            <Modal show={showw} onHide={handleClosee}>
                <Modal.Header closeButton className='w-75 mx-auto'>
                    <Modal.Title>تعديل الصلاحية</Modal.Title>
                </Modal.Header>
                <Modal.Body className='w-75 mx-auto mt-5 mb-5'>
                    <UbdateModel idd={selectedUserId} />
                </Modal.Body>
                <Modal.Footer className='w-75 mx-auto pt-0 mt-0 d-center'>
                    <Button variant="secondary px-5 mt-0 pt-0 mb-4" onClick={handleClosee}>
                        إغلاق
                    </Button>
                    {/* <Button variant="primary" onClick={handleClosee}>
                        حفظ التغييرات
                    </Button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
}
