import axios from 'axios';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';

export default function CreateTask({id}) {
  console.log(id);
  const [data, setData] = useState([]);
  const [authError, setAuthError] = useState(null);
  const [loading, setloading] = useState(false)
const [idd, setid] = useState([])
useEffect(() => {
  setid(id)

}, [idd])

console.log(idd);
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      setAuthError('No token found');
      return;
    }
  
    axios.get('https://api.bokaramstore.com/api/employees', {
      headers: {
        Authorization: `Bearer ${token}`,
        'Accept': 'application/json' // تحديد نوع المحتوى
      },
    })
    .then(response => {
      setData(response?.data?.data);
      // console.log("response" , response);
      setAuthError(null);
    })
    .catch(error => {
      if (error.response && error.response.status === 401) {
        setAuthError('Unauthenticated. Please check your token.');
      } else {
        setAuthError('Error fetching data');
      }
      console.error('Error fetching data:', error);
    });
  }, []);
  
  const initialValues = {
    name: '',
    time_min: '',
    time_hour: '',
    status: 'pending',
    user_id: '',
    project_id: Number(id),
    end_time:"",
    start_time:"",
    status: '',

  };

  const validationSchema = Yup.object({
    name: Yup.string().required('Name is required'),
    department: Yup.string().required('Department is required'),
    user_id: Yup.string().required('User ID is required'),
    time: Yup.string().required('Time is required'),
    remaining_time: Yup.string().required('Remaining time is required'),
    actual_time: Yup.string().required('Actual time is required'),
    first_test: Yup.string().required('First test date is required'),
    second_test: Yup.string().required('Second test date is required'),
    status: Yup.string().required('Status is required'),
    start_time: Yup.string().required('Status is start_time'),
    end_time: Yup.string().required('Status is end_time'),
    // project_id: Yup.string().required('Project ID is required'),
  });

  const onSubmit = async (values) => {
    try {
      setloading(true); // تعيين حالة التحميل إلى true عند بدء التحميل
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
  
      // Transform name to name[ar]
      const transformedValues = {
        ...values,
        'name': values.name,
      };
      // delete transformedValues.name;
  
      let date = await axios.post(`https://api.bokaramstore.com/api/projects-task/create`, transformedValues, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json' // Ensure the content type is set correctly
        }
      });
      // console.log('Data sent!', date.data);
      if (date.data.message === 'success') {
        toast("تم اضافة مهمة بنجاح");
        setloading(true); // تعيين حالة التحميل بعد الانتهاء
      }
    } catch (error) {
      console.error('Failed to send data:', error);
      toast.error("ليس لديك صلاحيه  ");
    } finally {
      setloading(false); // إعادة تعيين حالة التحميل بعد الانتهاء
    }
    console.log(values);
  };
  
  const formik = useFormik({
    initialValues,
    // validationSchema,
    onSubmit
  });

  return (
    <div className="container">
      {authError && <div className="alert alert-danger">{authError}</div>}
      <div className="row mx-4">
        <form onSubmit={formik.handleSubmit}>
    
          
          <div className="row">




          <div className="col-4">
            <label htmlFor="" className='fw-bold mb-2 mx-4 px-3'>المسئول</label>
            {data ? (
              <select
                name="user_id"
                className="form-select p-1 px-4 border border-1 border-black w-75 mx-auto"
                aria-label="Default select example"
                value={formik.values.user_id}
                onChange={(e) => {
                  formik.handleChange(e);
                  formik.setFieldValue('user_id', e.target.value);
                }}
                onBlur={formik.handleBlur}
              >
                <option value="" label="التيم المسئول لتصميم المشروع" />
                {data.map(user_id => (
                  <option key={user_id.id} value={user_id.id}>
                    {user_id.name}
                  </option>
                ))}
              </select>
            ) : (
              <div>Loading...</div>
            )}
            {formik.touched.user_id && formik.errors.user_id ? (
              <div className="error">{formik.errors.user_id}</div>
            ) : null}
          </div>

{/* <div className="col-3"></div> */}
          <div className="col-4 ">
        <label htmlFor="status" className="fw-bold mb-2 mx-4 px-3">الحاله</label>
        {data ? (
          <select
            name="status"
            className="form-select p-1 px-4 border border-1 border-black w-75 mx-auto"
            aria-label="Default select example"
            value={formik.values.status}
            onChange={(e) => {
              formik.handleChange(e);
              formik.setFieldValue('status', e.target.value);
            }}
            onBlur={formik.handleBlur}
          >
        <option value="pending">قيد الانتظار</option>
        <option value="current">قيد العمل</option>
          </select>
        ) : (
          <div>Loading...</div>
        )}
        {formik.touched.status && formik.errors.status ? (
          <div className="error">{formik.errors.status}</div>
        ) : null}
      </div>





          {/* <div className="col-4"></div> */}
            <div  className="col-4 mt">
              <label htmlFor="name  fw-bold">اسم المهمه</label>
              <input
                id="name"
                name="name"
                type="text"
                placeholder='اسم المهمه'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                className='form-control border border-black mt-2 w-100 p-1'
              />
              {formik.touched.name && formik.errors.name ? (
                <div className="error">{formik.errors.name}</div>
              ) : null}
            </div>
            <div  className="col-12">
              <label htmlFor="details mb-2 fw-bold">اسم المهمه</label>
              <textarea
                id="details"
                name="details"
                placeholder='وصف المهمه'
                type="text"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.details}
                className='form-control border border-black pt-2 w-100'
              />
              {formik.touched.details && formik.errors.details ? (
                <div className="error">{formik.errors.details}</div>
              ) : null}
            </div>



        
            <div  className="col-5 mt-4">
              <label htmlFor="time_min" className='mb-2'>الوقت بالدقائق</label>
              <input
                id="time_min"
                name="time_min"
                type="number"
                placeholder='الوقت بالدقائق'
                className='form-control border border-black pt-2 w-100'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.time_min}
              />
              {formik.touched.time_min && formik.errors.time_min ? (
                <div className="error">{formik.errors.time_min}</div>
              ) : null}
            </div>
            <div className="col-2"></div>
            <div  className="col-5 mt-4">
              <label htmlFor="time_hour" className='mb-2'>الوقت بالساعات</label>
              <input
                id="time_hour"
                name="time_hour"
                type="number"
                className='form-control border border-black pt-2 w-100'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.time_hour}
              />
              {formik.touched.time_hour && formik.errors.time_hour ? (
                <div className="error">{formik.errors.time_hour}</div>
              ) : null}
            </div>
        
            <div  className="col-5 mt-4">
              <label htmlFor="start_time" className='mb-2'> start_time</label>
              <input
                id="start_time"
                name="start_time"
                type="time"
                placeholder='الوقت بالدقائق'
                className='form-control border border-black pt-2 w-100'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.start_time}
              />
              {formik.touched.start_time && formik.errors.start_time ? (
                <div className="error">{formik.errors.start_time}</div>
              ) : null}
            </div>
            <div className="col-2"></div>
            <div  className="col-5 mt-4">
              <label htmlFor="end_time" className='mb-2'> end_time</label>
              <input
                id="end_time"
                name="end_time"
                type="time"
                className='form-control border border-black pt-2 w-100'
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.end_time}
              />
              {formik.touched.end_time && formik.errors.end_time ? (
                <div className="error">{formik.errors.end_time}</div>
              ) : null}
            </div>


           

       
     
        
            <div>
            <button type="submit" className='mt-3 btn bg-color px-4 text-white'>
  {loading ? (
    <div className="spinner-border text-light" role="status">
      <span className="visually-hidden">Loading.  ..</span>
    </div>
  ) : (
    "ارسال"
  )}
</button>
{/* {loading && (
  <button className='mt-3 btn bg-success px-4 text-white mx-2' onClick={() => setloading(false)}>تم التحميل</button>
)} */}

            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
