import React, { useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";

export default function Navbar() {
  const [isSubMenuOpen1, setIsSubMenuOpen1] = useState(false);
  const [isSubMenuOpen2, setIsSubMenuOpen2] = useState(false);
  const [isSubMenuOpen3, setIsSubMenuOpen3] = useState(false);


  const location = useLocation();
  const { pathname } = location;
  // console.log(pathname );

  const toggleSubMenu1 = () => {
    setIsSubMenuOpen1(!isSubMenuOpen1);
  };

  const toggleSubMenu2 = () => {
    setIsSubMenuOpen2(!isSubMenuOpen2);
  };

  const toggleSubMenu3 = () => {
    setIsSubMenuOpen3(!isSubMenuOpen3);
  };
  const navigate = useNavigate();

  const handleLogout = () => {
    // Remove token from localStorage
    localStorage.removeItem('token');
    // Navigate to the login page
    navigate("/login");
    window.location.href = window.location.href;
  };

  return (
    <div className="sidebar mt-4 ">
      
      {/* <div className="sidebar-header">Sidebar Header</div> */}
      <ul className="sidebar-menu border border-1 px-">
      <Link className="text-decoration-none" to={""}>
        
      <div  className={pathname == `/`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
        <i className="fas fa-home mx-2 "></i>
      الرئسيه
            </div>
      </Link>


        <li className="sidebar-menu-item ">
          <Link
            to="#"
            className="sidebar-menu-link linknone d-flex justify-content-between hoverstyle"
            onClick={toggleSubMenu1}
          >
           
            <div >
              <i className="fa fa-cog mx-2" aria-hidden="true"></i>
              الاعدادات
            </div>
            <div>
              {isSubMenuOpen1 ? (
        <i className="fa-solid fa-chevron-down"></i>
              ) : (
                <i className="fa fa-chevron-left"></i>
              )}
            </div>
          </Link>

          <ul className={`submenu ${isSubMenuOpen1 ? "open" : ""}`}>
            <Link className="text-decoration-none" to={"/Settings"}>
            <li >
              <div  className={pathname == `/Settings`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
                <i className="fa fa-cog mx-2 title" aria-hidden="true"></i>
                الاعدادات
              </div>
            </li>
            </Link>
        
            
<Link className="text-decoration-none" to={"/Emails_sending"}>
<li className="submenu-item  hoverli ">
<div  className={pathname == `/Emails_sending`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
  <i className="fa fa-envelope mx-2" aria-hidden="true"></i>
  إيميلات المرسلة
</div>
</li></Link>


<Link to={"services"} className="text-decoration-none">
<li className="submenu-item  hoverli">
<div  className={pathname == `/services`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
  <i className="fas fa-edit mx-2"></i>
  الاقسام
</div>
</li>
</Link>

<Link to={"Project"} className="text-decoration-none">
<li className="submenu-item  hoverli">
<div  className={pathname == `/Project`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
  <i className="fa fa-history mx-2"></i>
  تتبع المشاريع
</div>
</li>
  
</Link>

<Link to={"admin"} className="text-decoration-none">

<li className="submenu-item  hoverli">
<div  className={pathname == `/admin`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
  <i className="fa fa-history mx-2"></i>
  تتبع الأقسام
</div>
</li>

</Link>
<Link to={"Tasks"} className="text-decoration-none">

<li className="submenu-item  hoverli">
<div  className={pathname == `/Tasks`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
  <i className="fa fa-history mx-2"></i>
  تتبع المهام
</div>
</li>

</Link>
<Link to={"Othertasks"} className="text-decoration-none">

<li className="submenu-item  hoverli">
<div  className={pathname == `/Othertasks`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
  <i className="fa fa-history mx-2"></i>
  تتبع المهام الأخرى
</div>
</li>

</Link>



          </ul>
        </li>

        <li className="sidebar-menu-item ">
          <Link
            to="#"
            className="sidebar-menu-link linknone d-flex justify-content-between hoverstyle"
            onClick={toggleSubMenu2}
          >
       
            <div>
           <i className="fa-solid fa-user mx-2"></i>
           الموظفين
            </div>
            <div>
              {isSubMenuOpen2 ? (
               <i className="fa-solid fa-chevron-down"></i>
              ) : (
                <i className="fa fa-chevron-left"></i>
              )}
            </div>
            
          </Link>
          <ul className={`submenu ${isSubMenuOpen2 ? "open" : ""}`}>


          <Link to={"permissions"} className="text-decoration-none">
          <li className="submenu-item mt-2 hoverli">
          <div  className={pathname == `/permissions`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
              <i className="fa-solid fa-message mx-2"></i>
              الصلاحيات
              </div>
            </li>
          </Link>
           
            
          <Link to={"Teamwork"} className="text-decoration-none">

            <li className="submenu-item mt-2 hoverli">
            <div  className={pathname == `/Teamwork`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
           <i className="fa-solid fa-users mx-2"></i>
الموظفين
</div>
</li>
          </Link>


          </ul>
          {/* إضافة القائمة الفرعية هنا إذا لزم الأمر */}
        </li>

      
        <Link to={"daily_report"} className="text-decoration-none text-dark">
        <div  className={pathname == `/daily_report`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
           <i className="fa-solid fa-users mx-2"></i>
            تقرير يومي 
            </div>

        </Link>

        

        <Link to={"Olddaily_report"} className="text-decoration-none text-dark">


        <div  className={pathname == `/Olddaily_report`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
        <i className="fas fa-user-cog mx-2"></i>
   تقرير اسبوعي
            </div>

        </Link>
        <Link to={"Monthly_report"} className="text-decoration-none text-dark">


        <div  className={pathname == `/Monthly_report`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
        <i className="fas fa-user-cog mx-2"></i>
   تقرير شهري
            </div>

        </Link>
        <Link to={"email"} className="text-decoration-none text-dark">


        <div  className={pathname == `/email`?`text-end  fw-bold hoverstyle text-color activelinkstyle`:`text-end  fw-bold hoverstyle text-color`}>
        <i className="fa-solid fa-envelope mx-2"></i>
          البريد الاكتروني
            </div>

        </Link>


        <Link onClick={handleLogout}  className="text-decoration-none text-dark">
<div className="text-end  fw-bold hoverstyle  text-white border-r">
<i className="fas fa-sign-out mx-2"></i>
           تسجيل خروج
            </div>

        </Link>

    
      </ul>
    </div>
  );
}
