import axios from 'axios';
import React, { useEffect, useState } from 'react'

export default function GetData({id}) {

    const [data, setdata] = useState([])

    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get(`https://api.bokaramstore.com/api/projects-task/show/${id}`, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setdata(response.data?.data[0]);
            console.log(id);
            console.log("Ahmmedddd" , response.data?.data[0]);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);
  return <>
  <div className="container">
    <div className="row">
        <div className="col-12">
            <div className="border border-black mx-5">
                <div className="d-flex mx-4 pt-3 pb-4">
                    <p className='fw-bold'> الاسم</p>
                    <p className='mx-3'> {data?.user} </p>
                </div>
                <div className="d-flex mx-4 mb-3">
                    <p className='fw-bold'>تفاصيل المهمة</p>
                    <p className='mx-3'> {data?.details} </p>
                </div>
                <div className="d-flex mx-4 pt-3 pb-4">
                    <p className='fw-bold'>اجمالى مدة المهمة</p>
                    <p className='mx-3'> {data.time} </p>
                </div>
                <div className="d-flex mx-4 pt-3 pb-4">
                    <p className='fw-bold'>  وقت الأضافى</p>
                    <p className='mx-3'>  
                    
                    {data.delay_status === "delay" ? <>
                    
                    {data?.delay_status === "delay" && <p className="bg-danger delay-time mx-2">تأخير {data?.remaining_time}</p>}

                    {data?.delay_status === "onTime" && <p className="bg-red mx-1">انجاز {data.remaining_time?.slice(0, 5)}</p>}



                    </>  :<></>}
                       </p>
                </div>
                <div className="d-flex mx-4 pt-3 pb-4">
                    <p className='fw-bold'>   وقت بداية المهمة</p>
                    <p className='mx-3'>    {data.start_time} </p>
                </div>
                <div className="d-flex mx-4 pt-3 pb-4">
                    <p className='fw-bold'>     وقت نهاية المهمة</p>
                    <p className='mx-3'>  {data.end_time} </p>
                </div>

                <div className="d-flex mx-4 pt-3 pb-4">
                    <p className='fw-bold'>         تعليقات المهمه </p>
                    <p className='mx-3'>name</p>
                </div>
            </div>
        </div>
    </div>
  </div>

  
  </>
}
