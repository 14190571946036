import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import toast from 'react-hot-toast';
import ReactDatePicker from 'react-datepicker';
import Role from './rolesempl';
import { Oval } from 'react-loader-spinner';
const validationSchema = Yup.object({
  'ar': Yup.string().required('الاسم بالعربي مطلوب'),
  'name[en]': Yup.string().required('الاسم بالانجليزي مطلوب'),
  'start_date': Yup.date().required('تاريخ البدء مطلوب'),
  'num_day': Yup.number().required('عدد الأيام مطلوب'),
  'num_hour': Yup.number().required('عدد الساعات مطلوب'),
  'deadline': Yup.date().required('الموعد النهائي مطلوب'),
  'status': Yup.string().required('الحالة مطلوبة'),
  'employee[0].id': Yup.number().required('معرف الموظف مطلوب'),
  'employee[0].role': Yup.number().required('معرف الصلاحية مطلوب'),
});

export default function Updatemodel({id}) {
  console.log(id);
    const [loading, setLoading] = useState(false);
    const [employees, setEmployees] = useState([]);
const [selectedEmployee, setSelectedEmployee] = useState('');
const [selectdate, setselectdate] = useState([]);
const [selectedRole, setSelectedRole] = useState('');
const [selectedRoleId, setSelectedRoleId] = useState(''); 

    
const handleSelectChange = (event) => {
  setSelectedEmployee(event.target.value);
};

const handleSubmit = (selectedEmployee) => {
  // Send selected employee ID to the server
  console.log("Selected employee ID:", selectedEmployee);
};
const handleRoleChangee = (event) => {
  // Get the selected role ID from the event
  const selectedRoleId = event.target.value;
  setSelectedRoleId(selectedRoleId);
};


    useEffect(() => {
      async function fetchEmployees() {
          try {
              const token = localStorage.getItem('token');
              if (!token) {
                  throw new Error('No token found');
              }
              
              const response = await axios.get('https://api.bokaramstore.com/api/employees', {
                  headers: {
                      Authorization: `Bearer ${token}`
                  }
              });
              setEmployees(response?.data);
          } catch (error) {
              console.error('Failed to fetch employees:', error);
              toast.error("فشل في جلب بيانات الموظفين");
          }
      }

      fetchEmployees();
  }, []);

  

  const handleSendId = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }
  
      // Example of sending the ID to an API endpoint
      const response = await axios.post(
        `https://api.bokaramstore.com/api/projects/update/${id}`,
        { id: selectedEmployee }, // Use selectedEmployee state directly
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );
  
      // Handle response
      console.log(response.data);
      if (response.data.message === 'success') {
        toast.success("تم إضافة 000المهمة بنجاح");
      } else {
        toast.error("فشل في إرسال البيانات");
      }
    } catch (error) {
      console.error('Failed to send ID:', error);
      toast.error("فشل ف22222ي إرسال الهوية");
    } finally {
      setLoading(false);
    }
  };  
  const onSubmit = async (values) => {
    try {
        setLoading(true);
        const token = localStorage.getItem('token');
        if (!token) {
            throw new Error('No token found');
        }
        const formattedStartDate = values.startDate.toISOString().split('T')[0];
        const formatteddeadline = values.deadline.toISOString().split('T')[0];
        
        const transformedValues = {
          'name': {
            'en': values.nameAr,
            'ar': values.nameEn
        },
            'start_date': formattedStartDate,
            'num_day': values.numDay,
            'num_hour': values.numHour,
            'deadline': formatteddeadline,
            'image': values.image,
            'status': values.status,
            'employee[0][id]': selectedEmployee, // Selected employee ID
            'employee[0][role]': selectedRoleId // Selected role ID
        };
        
        const transformedValuesJSON = JSON.stringify(transformedValues);
        
        try {
            setLoading(true);
            const token = localStorage.getItem('token');
            if (!token) {
                throw new Error('No token found');
            }
        
            const response = await axios.post(
              `https://api.bokaramstore.com/api/projects/update/${id}`,
                transformedValuesJSON,
                {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'application/json'
                    }
                }
            );
        
            if (response?.data.message === 'success') {
                toast.success("تم تعديل المهمه بنجاح   ");
            } else {
                toast.error("فشل في إرسال البيانات");
            }
        } catch (error) {
          if (error.response && error.response.data && error.response.data.errors) {
            Object.values(error.response.data.errors).forEach((errArray) => {
                errArray.forEach((err) => {
                  console.log(err);
                    toast.error(err); // Show each error message
                });
            });
        } else {
            toast.error('Failed to send data! Please try again.'); // Show generic error toast message
        }
        console.log(error.response.data.errors);
        } finally {
            setLoading(false);
        }
        

        // if (response.data.message === 'success') {
        //     toast.success("تم إضافة المهمة بنجاح");
        // } else {
        //     toast.error("فشل في إرسال البيانات");
        // }
    } catch (error) {
        console.error('Failed to send data:', error);
        toast.error("فشل في إرسال البيانات");
    } finally {
        setLoading(false);
    }
};






useEffect(() => {
  async function getRoles() {
    const token = localStorage.getItem("token");
    if (!token) {
      // console.error('لم يتم العثور على رمز المصادقة في localStorage');
      throw new Error("لا يوجد رمز مصادقة في localStorage");
    }

    const config = {
      headers: {
        Authorization: `Bearer ${token}`, // تعيين رمز المصادقة في هيدر الطلب
      },
    };

    try {
      const response = await axios.get(
        `https://api.bokaramstore.com/api/roles`,
        config
      );
      // console.log(response.data.data);
      setselectdate(response?.data?.data);
    } catch (error) {
      // console.error('حدث خطأ:', error.message);
      throw error;
    }
  }
  getRoles()
}, [])

  // console.log(selectdate);
    return (
        <div className='container mx-4'>
            <Formik
     initialValues={{ nameAr: '', nameEn: '', startDate: null, numDay: '', numHour: '', deadline: null, status: '' }}                // validationSchema={validationSchema}
                onSubmit={onSubmit}
                
            >
                {({ setFieldValue, values, errors, touched }) => (
                    <FormikForm>
                        <div className="row">
                            <div className='col-4'>
                                <label className='fw-bold mt-3' htmlFor="nameAr">الاسم بالعربي</label>
                                <Field
                                    className='form-control w-75 border-input border border-black p-1 mt-2'
                                    type="text"
                                    id="nameAr"
                                    name="nameAr"
                                    placeholder="الاسم بالعربي"
                                />
                                <ErrorMessage name="nameAr" component="div" className="text-danger" />
                            </div>
                            <div className='col-4'>
                                <label className='fw-bold mt-3' htmlFor="nameEn">الاسم بالانجليزي</label>
                                <Field
                                    className='form-control w-75 border-input border border-black p-1 mt-2'
                                    type="text"
                                    id="nameEn"
                                    name="nameEn"
                                    placeholder="الاسم بالانجليزي"
                                />
                                <ErrorMessage name="nameEn" component="div" className="text-danger" />
                            </div>
                            <div className='col-4'>
    <label className='fw-bold mt-3' htmlFor="startDate">تاريخ البدء</label>
    <div className="d-flex align-items-center"> {/* Wrap both date picker and input field in a flex container */}
        <ReactDatePicker
            className='form-control w-75 border-input border border-black p-1 mt-2'
            selected={values.startDate}
            onChange={(date) => setFieldValue('startDate', date)}
            dateFormat="yyyy-MM-dd"
        />
    
    </div>
    <ErrorMessage name="startDate" component="div" className="text-danger" />
</div>
                            <div className='col-4'>
                                <label className='fw-bold mt-3' htmlFor="numDay">عدد الأيام</label>
                                <Field
                                    className='form-control w-75 border-input border border-black p-1 mt-2'
                                    type="number"
                                    id="numDay"
                                    name="numDay"
                                    placeholder="عدد الأيام"
                                />
                                <ErrorMessage name="numDay" component="div" className="text-danger" />
                            </div>
                            <div className='col-4'>
                                <label className='fw-bold mt-3' htmlFor="numHour">عدد الساعات</label>
                                <Field
                                    className='form-control w-75 border-input border border-black p-1 mt-2'
                                    type="number"
                                    id="numHour"
                                    name="numHour"
                                    placeholder="عدد الساعات"
                                />
                                <ErrorMessage name="numHour" component="div" className="text-danger" />
                            </div>
                            <div className='col-4'>
                                <label className='fw-bold mt-3' htmlFor="deadline">الموعد النهائي</label>
                                <ReactDatePicker
                                    className='form-control w-75 border-input border border-black p-1 mt-2'
                                    selected={values.deadline}
                                    onChange={(date) => setFieldValue('deadline', date)}
                                    dateFormat="yyyy-MM-dd"
                                />
                                <ErrorMessage name="deadline" component="div" className="text-danger" />
                            </div>
                            <div className='col-4'>
    <label className='fw-bold mt-3' htmlFor="status">الحالة</label>
    <Field
        className='form-control w-75 border-input border border-black p-1 mt-2'
        as="select" // استخدم <select> بدلاً من <input>
        id="status"
        name="status"
    >
        <option value="pending">قيد الانتظار</option>
        <option value="current">حالي</option>
        <option value="complete">مكتمل</option>
        <option value="planning">تخطيط</option>
    </Field>
    <ErrorMessage name="status" component="div" className="text-danger" />
</div>
<div className="col-4">
      
<div className=" mt-4">
          <label htmlFor="role"> الصلاحيه</label>
          <select
    id="role"
    name="role"
    className="form-control w-75 border-input border border-black p-1 mt-2"
    onChange={handleRoleChangee}
    value={selectedRoleId} // Use selectedRoleId state directly
  >
    <option value="" disabled>
      اختر...
    </option>
    {selectdate?.map((item) => (
      <option key={item.value} value={item.id} clas> {/* Use item.id instead of item.value */}
        {item.name}
      </option>
    ))}
  </select>


       
        </div>

</div>
<div className=" mt-4 col-4">
          <label htmlFor=""> اسم المظف</label>
          <select value={selectedEmployee} onChange={handleSelectChange} className='form-control w-75 border-input border border-black p-1 mt-2'>
  <option value="" > اسم الموظف </option>
  {employees?.data?.map(employee => (
    <option key={employee.id} value={employee.id} className='form-control w-75 border-input border border-black p-1 mt-2'>{employee.name}</option>
  ))}
</select>


       
        </div>
{/* <div className='col-4'>
                <label className='fw-bold mt-3' htmlFor="image">الصورة</label>
                <Field
    type="file"
    name="image"
    id="image"
    onChange={(event) => {
        const file = event.currentTarget.files[0];
        console.log(file);
        const reader = new FileReader();
        reader.onloadend = () => {
            setFieldValue('image', reader.result);
        };
        reader.readAsDataURL(file);
    }}
    className='form-control border-input border border-black p-1 mt-2'
/>

                <ErrorMessage name="image" component="div" className="text-danger" />
            </div> */}
                <div>
 
{/* 
                <Oval
  visible={true}
  height="80"
  width="80"
  color="#4fa94d"
  ariaLabel="oval-loading"
  wrapperStyle={{}}
  wrapperClass=""
  /> */}
            {/* <button onClick={handleSendId}>Submit</button> */}
            {/* Render loading indicator if loading */}
            {loading && <p>Loading...</p>}
        </div>

                        </div>
                        <div className="w-100 mt-4">
                            <Button  className='col-1 btn bg-transparent' disabled={loading}>
                                {loading ?   <Oval
  visible={true}
  height="80"
  width="80"
  color="#4fa94d"
  ariaLabel="oval-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />: <button type="submit" variant="primary" className='btn text-white bg-color px-4'>ارسال</button>}
                            </Button>
                        </div>
                    </FormikForm>
                )}
            </Formik>
        </div>
    );
}
