import React, { useEffect, useState } from 'react';
import axios from 'axios';
import toast, { Toaster } from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

export default function UbdateModel({idd}) {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [formData, setFormData] = useState({
    name: '',
    permissions: []
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({ ...formData, permissions: [...formData.permissions, value] });
    } else {
      setFormData({ ...formData, permissions: formData.permissions.filter(permission => permission !== value) });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true); // تعيين حالة التحميل لـ true
  
    axios.post(`https://api.bokaramstore.com/api/roles/update/${idd}`, formData)
      .then((response) => {
        toast.success(response?.data?.message);
        // console.log(response?.data);
      })
      .catch((error) => {
        toast.error('حدث خطأ أثناء الإرسال إلى ال API: ', error);
        // console.error('حدث خطأ أثناء الإرسال إلى ال API: ', error);
      })
      .finally(() => {
        setIsLoading(false); // تعيين حالة التحميل لـ false بغض النظر عن نجاح أو فشل الطلب
      });
  };
  

  const fetchData = () => {
    axios.get('https://api.bokaramstore.com/api/permissions')
      .then((response) => {
        // console.log(response?.data?.data, "AHMMEDX8777");
        setData(response?.data?.data);
      })
      .catch((error) => {
        toast.error('حدث خطأ أثناء جلب البيانات: ', error);
        // console.error('حدث خطأ أثناء جلب البيانات: ', error);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <div className="container">
        <div className="row mx-4">
          <form onSubmit={handleSubmit} className='row'>
            <div className="col-6 mt-4">
              <label className="w-100">الأسم</label>
              <input
                type="text"
                name="name"
                className="form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-"
                placeholder="الأسم"
                value={formData.name}
                onChange={handleChange}
              />
            </div>
            <div className="mt-4">
              <h3 className="text-muted">الصلاحيات</h3>
              <div className="row mt-4">
                {data.map((item, index) => (
                  <div className="col-3" key={index}>
                    <div className="d-flex">
                      <input
                        type="checkbox"
                        className="form-check-input mt-2 border border-2 border-black"
                        id={`exampleCheck${index}`}
                        value={item.name}
                        onChange={handleCheckboxChange}
                        checked={formData.permissions.includes(item.name)}
                      />
                      <div className="mb-3 form-check w-50 mx-2">
                        <label className="form-check-label" htmlFor={`exampleCheck${index}`}>
                          {item.name}
                        </label>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <button type="submit" className={` mt-4 btn text-end border-0 ${isLoading ? 'disabled' : ''}`} disabled={isLoading}>
  {isLoading ? <Oval
  visible={true}
  height="80"
  width="80"
  color="white"
  ariaLabel="oval-loading"
  wrapperStyle={{}}
  wrapperClass=""
  /> : <button className='btn bg-color w-25 text-white'>ارسال</button>}
</button>

          </form>
          <Toaster /> {/* تم إضافة Toaster هنا لعرض تنبيهات الـ toast */}
        </div>
      </div>
    </>
  );
}
