import React, { useContext } from 'react'
import { Link } from 'react-router-dom'
import { MyContext } from '../component/Context/Context'

export default function Home() {

  let {setRecords} = useContext(MyContext)
  
  return <>
  
  
  
  <div className="row mt-3">
    <div className="col-4 mt-3 pointer">
    <Link to={"/Future"} className='text-decoration-none'>
            <div className="boxshado mx-2">
        <div className="d-flex justify-content-between px-3 p-4">
        <div>
          <i className="fas fa-edit text-color"></i>
          </div>
          <div>
            <p className='text-color text-end font-23'>0</p>
            <p className='fw-bold title'>المشاريع المستقبليه</p>
          </div>
      
        </div>
      </div>
    </Link>

    </div>


    <div className="col-4 mt-3 pointer">
    <Link to={"/wait"} className='text-decoration-none'>
            <div className="boxshado mx-2">
        <div className="d-flex justify-content-between px-3 p-4">
        <div>
          <i className="fas fa-edit text-color"></i>
          </div>
          <div>
            <p className='text-color text-end font-23'>0</p>
            <p className='fw-bold title'> المشاريع المنتظرة</p>
          </div>
      
        </div>
      </div>
    </Link>

    </div>


    <div className="col-4 mt-3 pointer">
    <Link to={"/current_myprojects"} className='text-decoration-none'>
      <div className="boxshado mx-2">
        <div className="d-flex justify-content-between px-3 p-4">
        <div>
          <i className="fas fa-edit text-color"></i>
          </div>
          <div>
            <p className='text-color text-end fw-bold font-23'>{setRecords.length}</p>
            <p className='fw-bold title'> المشاريع الحالية</p>
          </div>
      
        </div>
      </div>
    </Link>
      
    </div>
    <div className="col-4 mt-3 pointer">
  
      <div className="boxshado mx-2">
        <div className="d-flex justify-content-between px-3 p-4">
        <div>
          <i className="fas fa-edit text-color"></i>
          </div>
          <div>
            <p className='text-color text-end font-23'>0</p>
            <p className='fw-bold title'>  المشاريع المنتهية </p>
          </div>
      
        </div>
      </div>

    </div>
    <div className="col-4 mt-3 pointer">
    <Link to={"/TastProject"} className='text-decoration-none'>
    <div className="boxshado mx-2">
        <div className="d-flex justify-content-between px-3 p-4">
        <div>
          <i className="fas fa-edit text-color"></i>
          </div>
          <div>
            <p className='text-color text-end font-23'>0</p>
            <p className='fw-bold title'>   المهام الأخرى  </p>
          </div>
      
        </div>
      </div>
    </Link>
   
    </div>
  </div>
  </>
}
