import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams } from "react-router-dom";
import { Modal, Button, DropdownButton, Dropdown } from "react-bootstrap";
import DataTable from "react-data-table-component";
import axios from "axios";
import DeleteAll from "../DeleteAll/DeleteAll";
import CreateTask from "../CreateTask";
import Waitingcapacity from "../Waitingcapacity";
import TaskComment from "../TaskComment";
import AddComment from "../Comment/AddComment";
import GetData from "../Comment/GetData";
import DeleteTask from "../DeletTask/DeletTask";
import UbdateTask from "../UpdateTask/UpdateTask";
import DeletTask from "../DeletTask/DeletTask";
import { jsPDF } from 'jspdf';
import { PDFViewer } from "@react-pdf/renderer";

import 'jspdf-autotable';
// import CustomFont from './path-to-your-font/CustomFont.ttf'; // Adjust the path accordingly
import 'jspdf-autotable';
import '@fontsource/amiri'; // Import the font
 
export default function Olddaily_report() {
// const amiriRegularBase64 = 'data:font/truetype;charset=utf-8;base64,AAEAAAARAQAABAAwRFNJRwAAAAEAA...';

  const [isChecked, setIsChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [projects, setProjects] = useState([]);
  const [date, setdata] = useState([])
  const [show, setShow] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const handleClose = () => setShow(false);
  const [showw, setShoww] = useState(false);
  const [sho, setSho] = useState(false);
  const [sh, setSh] = useState(false);
  const [s, setS] = useState(false);
  const [shh, setShh] = useState(false);
  const handleClos = () => setSho(false);
  const handleClo = () => setSh(false);
  const handleCl = () => setS(false);
  const handleClhh = () => setShh(false);

  const [showeee, setShoweee] = useState(false);

  const handleCloseeee = () => setShoweee(false);
  const handleShoweee = () => setShoweee(true);

  const handleShow = (id) => {
    setSelectedId(id);
    setShow(true);
  };


  const handleSho = (id) => {
    setSelectedId(id);
    setSho(true);
  };
  const handleSh = (id) => {
    setSelectedId(id);
    setSh(true);
  };

  const handleS = (id) => {
    setSelectedId(id);
    setS(true);
  };

  const handleShh = (id) => {
    setSelectedId(id);
    setShh(true);
  };

  

  const handleCheckChange = (e) => {
    const newValue = e.target.checked;
    setIsChecked(newValue);
  };
  const handleModalClose = () => {
    setShowModal(false);
  };
  const handleModalShow = () => {
    setShowModal(true);
  };




  const [showww, setShowww] = useState(false);

  const handleCloseee = () => setShowww(false);
  // const handleShowww = () => setShowww(true);
  const handleShowww = (id) => {
    setSelectedId(id);
    setShowww(true);
  };
  

  const colums = [
    {
      name:" المهام",
      selector:row=>row.name,
      sortable:false
    },
    {
      name:" المده ",
      selector:row=>row.time,
      sortable:true
    },
    {
      name:" الوقت الفعلي للمهمه ",
      selector:row=>row.actual_time,
      sortable:true
    },

    
    {
      name: "تعليقات المشروع",
      cell: (row) =>   <div
      key={row.id}
      className="cu fw-bold"
      onClick={() => handleShowww(row.id)}
    >
      عرض التعليقات





    </div>, // Assuming each row has a 'comment' field
      sortable: true,
      selector: (row) => row.comment,
    },
    {
      name: "الحاله",
      cell: (row) => (
        <div className="w-100">
          {row.status === "pending" ? (
            <div className="bg-red text-center text-white border rounded p-1 cu" onClick={() => handleShow(row.id)}>
              قيد الانتظار
            </div>
          ) : row.status === "current" ? (
            <div className="bg-green text-center text-white border rounded p-1 cu" onClick={() => handleShow(row.id)}>
              قيد العمل
            </div>
          ) : row.status === "completed" ? (
            <div className="bg-color text-center text-white border rounded p-1 d-flex justify-content-between">
              <p className="mx-1">مكتمل</p>
              {row.delay_status === "delay" && <p className="bg-danger mx-1">تأخير {row.remaining_time?.slice(0, 5)}</p>}
              {row.delay_status === "onTime" && <p className="bg-red mx-1">انجاز {row.remaining_time?.slice(0, 5)}</p>}
            </div>
          ) : row.status === "planing" && (
            <div className="bg-color text-center text-white border rounded p-1">
              مستقبلياً
            </div>
          )}
        </div>
      ),
      sortable: true,
      selector: (row) => row.status,
    }
,    
    
    
    {
      name:"        المسئول	  		 	   ",
      selector:row=><>  
      
 
        {row.user}
       </>,
      sortable:true
    },
    {
      name:"        اسم المشروع	  		 	   ",
      selector:row=><>  
      
 
        {row.project}
       </>,
      sortable:true
    },
    // {
    //   name:"	      اسم المشروع	",
    //   selector:row=>     
    //     <button
    //         className="btn bg-color p-1 text-white mx-3"
    //         // onClick={handleSho}
    //         onClick={() => handleSho(row.id)}
    //       >
    
    //         <i className="fa-solid fa-plus"></i> اضفة تعليق
    //       </button>,
    //   sortable:true
    // },
    {
      name:" تفاصل المشروع",
      selector:row=>
  <>
        <button
      className="btn bg-color btn-sm  text-white px-2 p-1"
      // onClick={handleSho}
      onClick={() => handleSh(row.id)}
    >

<i class="far fa-clock"></i>
    </button>
        {/* <button
      className="btn bg-danger btn-sm  text-white px-2 p-1 mx-1"
      onClick={() => handleS(row.id)}
    >

<i class="fa fa-trash" aria-hidden="true"></i>
    </button>
        <button
      className="btn bg-warning btn-sm  text-white px-2 p-1 mx-1"
      onClick={() => handleShh(row.id)}
    
    >

<i class="fas fa-edit"></i>
    </button> */}
    </>
    ,
        // row.remaining_time,
      sortable:true
    },
  ]

  // const exportPDF = () => {
  //   const doc = new jsPDF();
  //   doc.addFileToVFS('Amiri-Regular.ttf');
  //   doc.addFont('Amiri-Regular.ttf', 'Amiri', 'normal');
  //   doc.setFont('Amiri');
  //   const tableColumn = colums.map(column => column.name);
  //   const tableRows = date?.map(item => colums.map(column => item[column.selector(item)]));
  
  //   doc.autoTable({
  //     head: [tableColumn],
  //     body: tableRows,
  //     startY: 20,
  //   });

  //   doc.save('datatable.pdf');
  // };







  const data = [
   
      {
        id:1,
        name:"سيستم النجار	",
        email:"1 ",
        age:"3",
        row:"Active	",
        startdate:"1/1/2002",
        enddate:"1/1/2002",
        datea:"1/1/2002",
        updautes:"0يوم",
        counterhuor:"صحة حلالك	",
        counterhuorr:"غير محدد",
        condition:"غير محدد",
        administrator:"Ahmed",
      },
 

  ]
  const [records, setrecords] = React.useState(data);
  function handleFilter(event) {
    const newData = data.filter((row) => {
      return row.name
        .toLocaleUpperCase()
        .includes(event.target.value.toLocaleUpperCase());
    });
    setrecords(newData);
  }

  const {id} = useParams();

  
  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`https://api.bokaramstore.com/api/projects/edit/${id}`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    })
    .then(response => {
      setProjects(response.data?.data);
        // console.log("Ahmmedddd" , response.data?.data);
    })
    .catch(error => {
        console.error('Error fetching data:', error);
    });
}, []);






useEffect(() => {
  const token = localStorage.getItem('token');
  axios.get(`https://api.bokaramstore.com/api/tasks-report/weekly`, {
      headers: {
          Authorization: `Bearer ${token}`
      }
  })
  .then(response => {
    setdata(response?.data?.data);
    // console.log(response.data.data);
  })
  .catch(error => {
      console.error('Error fetching data:', error);
  });
}, [date]);
// console.log(date, "opsdfj");


// console.log(projects.employees , "Location");
const amiriRegularBase64 = 'data:font/truetype;charset=utf-8;base64,AAEAAAARAQAABAAwRFNJRwAAAAEAA...';


const exportPDF = () => {
  const doc = new jsPDF();

  // Add the custom font from the Base64 string
  doc.addFileToVFS('Amiri-Regular.ttf', amiriRegularBase64);
  doc.addFont('Amiri-Regular.ttf', 'Amiri', 'normal');
  doc.setFont('Amiri');

  doc.text('Data Table PDF', 14, 16);

  const tableColumn = colums.map(column => column.name);
  const tableRows = date.map(item => colums.map(column => item[column.selector(item)]));

  doc.autoTable({
    head: [tableColumn],
    body: tableRows,
    startY: 20,
  });

  doc.save('datatable.pdf');
};


// console.log(projects[0].name , "projectsprojects");
  return (
    <>
        <button onClick={exportPDF}>Export to PDF</button>
       {/* <button onClick={exportPDF}>Export to PDF</button> */}

      <Link className="fw-bold text-dark" to={"/"}>
        الرئسيه
      </Link>
      <Link className="text-muted mx-2 text-decoration-none mx-4">
    تقرير اسبوعي
      </Link>

    

      <div className="mt-4 d-flex">
        {/* <button
          className="btn bg-color p-1 text-white mx-3"
          
          onClick={handleModalShow}
        >
          <i className="fa-solid fa-plus"></i> اضافه{" "}
        </button> */}
        {/* <button className="btn bg-danger p-1 text-white px-3" onClick={handleShoweee}>
        <i class="fa fa-trash" aria-hidden="true"></i> حذف الكل
        </button> */}
      </div>

      {/* ----------- start-Table ------------------------*/}
      <div className="col-3">
        <input
          type="search"
          placeholder="search"
          className="text-end form-control bg-gray mt-3 mb-3"
          onChange={handleFilter}
        />
      </div>
      <DataTable
        columns={colums}
        data={date}
        selectableRows
        pagination="auto"
      ></DataTable>
      {/* ----------- end-Table ------------------------*/}
      <Modal show={showModal} onHide={handleModalClose}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-5 pt-3 text-color "> الأضافة</Modal.Title>
        </Modal.Header>
        <Modal.Body>

         <CreateTask id={id}></CreateTask>

        </Modal.Body>
        <Modal.Footer className="text-center d-center mb-4 mt-4">
          {/* <Button
            variant="secondary"
            className="px-4 mx-4"
            onClick={handleModalClose}
          >
            إغلاق
          </Button>
          <Button variant="primary" className="px-4" onClick={handleModalClose}>
            حفظ
          </Button> */}
        </Modal.Footer>
      </Modal>






      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title className="pt-3 px-5">تغير حالة المهمه</Modal.Title>
        </Modal.Header>
        <Modal.Body>   <Waitingcapacity id={selectedId}></Waitingcapacity>  </Modal.Body>
        <Modal.Footer className="mx-5 mb-1">
        
        </Modal.Footer>
      </Modal>




      <Modal show={showw} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>Woohoo, you are reading this text in a modal!</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {/* <Button variant="primary" onClick={handleClose}>
            Save Changes
          </Button> */}
        </Modal.Footer>
      </Modal>




      <Modal show={showww} onHide={handleCloseee}>
        <Modal.Header closeButton className="mx-4">
          <Modal.Title className="pt-3 pb-3">Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4 mt-4">

        <TaskComment id={selectedId} />


          </Modal.Body>
        <Modal.Footer className="mx-5 mb-4 mt-4">
          <Button variant="secondary" onClick={handleCloseee} className="px-4">
            Close
          </Button>
      
        </Modal.Footer>
      </Modal>



      <Modal show={sho} onHide={handleClos}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-4 pt-3">اضافة تعليق </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AddComment id={selectedId}></AddComment>
        </Modal.Body>
        <Modal.Footer className="mx-3 mt-3 mb-2">
          <Button variant="secondary" className="px-4" onClick={handleClos}>
            Close
          </Button>
 
        </Modal.Footer>
      </Modal>


      
      <Modal show={sh} onHide={()=>handleClo(id)}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-3 pt-3 mx-5"> تفاصيل المهمه </Modal.Title>
        </Modal.Header>
        <Modal.Body>

       <GetData id={selectedId}></GetData>
        </Modal.Body>
        <Modal.Footer className="mx-5 mt-3 mb-2">
          <Button variant="secondary" className="px-4" onClick={handleClo}>
            Close
          </Button>
 
        </Modal.Footer>
      </Modal>


      
      <Modal show={s} onHide={()=>handleCl(id)}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-3 pt-3 mx-5">  حذف المهمه </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex w-75">
      <>
      
      
      <DeletTask id={selectedId}/>
      <button   className=" btn-secondary btn px-4 h-30 mt-5" onClick={handleCl} >
            لا
          </button>
      </>
        </Modal.Body>
        <Modal.Footer className="mx-5 mt-3 mb-2">

 
        </Modal.Footer>
      </Modal>
      <Modal show={shh} onHide={()=>handleClhh(id)}>
        <Modal.Header closeButton>
          <Modal.Title className="mb-3 pt-3 mx-5">   تعديل المهمه </Modal.Title>
        </Modal.Header>
        <Modal.Body className="d-flex ">
      <>
      
      
      <UbdateTask id={selectedId}/>

      </>
        </Modal.Body>
        <Modal.Footer className="mx-5 mt-3 mb-2">

 
        </Modal.Footer>
      </Modal>




      <Modal show={showeee} onHide={handleCloseeee}>
        <Modal.Header closeButton>
          <Modal.Title className="mx-4 pt-4 pb-4">هل متأكد من حذف كل المهمات؟</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4 d-flex d-flex justify-content-between w-75 mx-auto">
         <DeleteAll/>
         <Button variant="secondary" className="h-30 px-4 mt-3" onClick={handleCloseeee}>
            لا
          </Button>
          </Modal.Body>
        <Modal.Footer>
       
        
        </Modal.Footer>
      </Modal>


    </>
  );
}
