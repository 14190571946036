import React from 'react'
import { Link } from 'react-router-dom'

export default function Settings() {
  return<>
  
  

<div className="d-fle container ">
<Link className='  fw-bold text-dark' to={"/"}>الرئسيه</Link>
 <Link className='text-muted mx-2 text-decoration-none mx-4'>الاعدادات</Link>
 <div className="mt-4 border-w vh-50">
    <div className="bg-color w-100">
       <div className="p-1">
       <h4 className="text-white mx-3">الاعدادات</h4>
       </div>
    </div>
 <div className="text-center mt-5">
    <div className="w-100">

    <img src="https://bokaramstore.com/pm/uploads/site_setting/KRtz.jpg" alt="" />
    </div>
    <label htmlFor="file" className=' mt-4 bg-color text-white p-2 pointer'>
        لوجو
    </label>
    <input type="file" className='d-none' name="file" id="file" />
 </div>


<div className="w-75 mx-auto">
    <div className="d-center flex-column">
{/* <label htmlFor="" className='text-m'>عنوان السيستم</label> */}
<div className="w-100 d-center">

<input type="text" name="" id="" className='form-control border-w mt-2 border-w w-50 mt-2 d-center' placeholder='عنوان السيستم'/>
</div>
<div className="w-100 d-center mt-2">

<input type="text" name="" id="" className='form-control border-w mt-2 border-w w-50 mt-2 d-center' placeholder=' بريد الالكترونى الخاص بالسيستم'/>
</div>
    </div>
</div>
    
    <div className="bg-color w-100 left-0 mt-4 h-10">
       <div className="p-1">
       <h4 className="text-white mx-3 "></h4>
       </div>
    </div>
 </div>
</div>
  
  </>
}
