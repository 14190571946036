import axios from 'axios';
import React, { useEffect, useState } from 'react';

export default function TaskComment({ id }) {
  const [data, setData] = useState([]);

  useEffect(() => {
    const token = localStorage.getItem('token');
    axios.get(`https://api.bokaramstore.com/api/tasks-comment/${id}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
    .then(response => {
      setData(response?.data?.data || []);
      console.log(response.data.data);
    })
    .catch(error => {
      console.error('Error fetching data:', error);
    });
  }, [id]);

  return (
    <div className="container">
      <div className="row">
        {data.length > 0 ? (
          data.map((item) => (
            <div className="col-12 mt-3" key={item.id}>
              <div className="border border-2 border-black">
                <div className="mx-4">
                  <div className="d-flex pt-4">
                    <p className="fw-bold">التعليقات</p>
                    <p className="mx-3">{item.comment}</p>
                  </div>
                  <div className="d-flex pt-2 pb-3">
                    <p className="fw-bold">وقت انشاء التعليق</p>
                    <p className="mx-3">{item.created_at}</p>
                  </div>
                  <div className="d-flex pt-2 pb-3">
                    <p className="fw-bold">وقت تحديث التعليق</p>
                    <p className="mx-3">{item.updated_at}</p>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <h4 className='text-center mx-auto fw-bold '>لا يوجد تعليقات</h4>
        )}
      </div>
    </div>
  );
}
