import React, { useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import { Link } from 'react-router-dom'

export default function Projectt() {
    
  const [selectedDate, setSelectedDate] = useState(null);

  return<>
  
  

<div className="d-fle container ">
<Link className='  fw-bold text-dark' to={"/"}>الرئسيه</Link>
 <Link className='text-muted mx-2 text-decoration-none mx-4'>المشاريع المستقبلية </Link>
 <div className="mt-4 border-w vh-50">
    <div className="bg-color w-100">
       <div className="p-1">
       <h4 className="text-white mx-3">اضافة مشروع</h4>
       </div>
    </div>



<div className="mt-5 container">
    <div className="row">
{/* <label htmlFor="" className='text-m'>عنوان السيستم</label> */}
<div className=" col-3">
<label className='w-100'>اسم المشروع
</label>
<input type="text" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray ' placeholder=' اسم المشروع
'/>
</div>
  <div className="col-3">
      <label className='w-100 mx-2'>تاريخ البداية</label>
      <ReactDatePicker
        className='form-control border-w mt-2 w-100 d-center bg-gray'
        selected={selectedDate}
        onChange={date => setSelectedDate(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="تاريخ البداية"
      />
    </div>


<div className=" col-3 ">
<label className='w-100 '>  تاريخ الأنتهاء
</label>
   <ReactDatePicker
        className='form-control border-w mt-2 w-100 d-center bg-gray'
        selected={selectedDate}
        onChange={date => setSelectedDate(date)}
        dateFormat="dd/MM/yyyy"
        placeholderText="تاريخ البداية"
      />
</div>
<div className='col-3 m'>
<label className=' m'>   
مدير المشروع
</label>
<select className="form-select w-75 bg-gray w-25 mt-2" aria-label="Default select example">

  <option selected>بدون</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
</div>



<div className='col-12 mt-3'>
<label className='w-100 '>   تفاصيل المشروع
</label>
    <textarea className="form-control mt-2 border border-2" style={{  height: '100px' }} placeholder="تفاصيل المشروع"/>
</div>
<div className='col-3 mt-4'>
<label className=' m'>   
حالة التنفيذ
</label>
<select className="form-select w-75 bg-gray w-25 mt-2" aria-label="Default select example">

  <option selected>حالة التنفيذ</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
</div>
<div className='col-3 mt-4'>
<label className=' m'>   
حالة المشروع
</label>
<select className="form-select w-75 bg-gray w-25 mt-2" aria-label="Default select example">

  <option selected> مشروع منتظر</option>
  <option value="1">One</option>
  <option value="2">Two</option>
  <option value="3">Three</option>
</select>
</div>
<div className=" col-3 mt-4">
<label className='w-100 '>   اجمالى ساعات المشروع
</label>
<input type="number" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='      اجمالى ساعات المشروع 
'/>
</div>
<div className=" col-3 mt-4">
<label className='w-100 '>     
اجمالى ايام المشروع
</label>
<input type="number" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='     
اجمالى ايام المشروع
'/>
</div>


<div className='col-3 mt-5'>
    <div className='d-flex'>
    <input type="checkbox" className="form-check-input border mx-2 border-2 border-dark font-22" id="exampleCheck1"/>
<div className="form-check w-50">
    <label className="form-check-label" for="exampleCheck1">Social media</label>
  </div>
    </div>
    <input type="number" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='      اجمالى ساعات  
'/>
</div>
<div className='col-3 mt-5'>
    <div className='d-flex'>
    <input type="checkbox" className="form-check-input border mx-2 border-2 border-dark font-22" id="exampleCheck1"/>
<div className="form-check w-50">
    <label className="form-check-label" for="exampleCheck1">Graphic Design</label>
  </div>
    </div>
    <input type="number" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='      اجمالى ساعات  
'/>
</div>
<div className='col-3 mt-5'>
    <div className='d-flex'>
    <input type="checkbox" className="form-check-input border mx-2 border-2 border-dark font-22" id="exampleCheck1"/>
<div className="form-check w-50">
    <label className="form-check-label" for="exampleCheck1"> Testing</label>
  </div>
    </div>
    <input type="number" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='      اجمالى ساعات  
'/>
</div>
<div className='col-3 mt-5'>
    <div className='d-flex'>
    <input type="checkbox" className="form-check-input border mx-2 border-2 border-dark font-22" id="exampleCheck1"/>
<div className="form-check w-50">
    <label className="form-check-label" for="exampleCheck1">  App Development</label>
  </div>
    </div>
    <input type="number" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='      اجمالى ساعات  
'/>
</div>
<div className='col-3 mt-5'>
    <div className='d-flex'>
    <input type="checkbox" className="form-check-input border mx-2 border-2 border-dark font-22" id="exampleCheck1"/>
<div className="form-check w-50">
    <label className="form-check-label" for="exampleCheck1">  Backend Development</label>
  </div>
    </div>
    <input type="number" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='      اجمالى ساعات  
'/>
</div>
<div className='col-3 mt-5'>
    <div className='d-flex'>
    <input type="checkbox" className="form-check-input border mx-2 border-2 border-dark font-22" id="exampleCheck1"/>
<div className="form-check w-50">
    <label className="form-check-label" for="exampleCheck1">  Web Design</label>
  </div>
    </div>
    <input type="number" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='      اجمالى ساعات  
'/>
</div>
<div className='col-3 mt-5'>
<label className=' m'>   
 لوجو المشروع
</label>
<input type="file" name="" id="" className='form-control border-w mt-2 border-w w-75 mt-2 d-center bg-gray mx-' placeholder='     
اجمالى ايام المشروع
'/>
</div>



    </div>
<button className='btn bg-color text-white px-3 p-1 w-25 mt-4 mb-4'>حفظ البانات</button>
</div>

 </div>
</div>
  
  </>
}
