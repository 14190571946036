import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { Button } from 'react-bootstrap';
import * as Yup from 'yup';
import { Formik, Form as FormikForm, Field, ErrorMessage } from 'formik';
import toast from 'react-hot-toast';
import ReactDatePicker from 'react-datepicker';
import Select from 'react-select';
import { Oval } from 'react-loader-spinner';

const validationSchema = Yup.object({
  'nameAr': Yup.string().required('الاسم بالعربي مطلوب'),
  'nameEn': Yup.string().required('الاسم بالانجليزي مطلوب'),
  'startDate': Yup.date().required('تاريخ البدء مطلوب'),
  'numDay': Yup.number().required('عدد الأيام مطلوب'),
  'numHour': Yup.number().required('عدد الساعات مطلوب'),
  'deadline': Yup.date().required('الموعد النهائي مطلوب'),
  'status': Yup.string().required('الحالة مطلوبة'),
});

export default function ModelObject() {
  const [loading, setLoading] = useState(false);
  const [employees, setEmployees] = useState([]);
  const [selectdate, setSelectdate] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null); // Ensure this is defined

  useEffect(() => {
    async function fetchEmployees() {
      try {
        const token = localStorage.getItem('token');
        if (!token) {
          throw new Error('No token found');
        }

        const response = await axios.get('https://api.bokaramstore.com/api/employees', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setEmployees(response?.data);
      } catch (error) {
        console.error('Failed to fetch employees:', error);
        toast.error("فشل في جلب بيانات الموظفين");
      }
    }

    fetchEmployees();
  }, []);

  useEffect(() => {
    async function getRoles() {
      const token = localStorage.getItem("token");
      if (!token) {
        throw new Error("لا يوجد رمز مصادقة في localStorage");
      }

      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await axios.get(
          `https://api.bokaramstore.com/api/roles`,
          config
        );
        setSelectdate(response?.data?.data);
      } catch (error) {
        throw error;
      }
    }
    getRoles();
  }, []);

  const handleSelectChangee = (selectedOptions) => {
    setSelectedEmployees(selectedOptions || []);
  };

  const handleRoleChangee = (selectedOption) => {
    setSelectedRole(selectedOption); // Update selectedRole state
  };

  // Convert employee and role data to format suitable for react-select
  const employeeOptions = employees?.data?.map(employee => ({
    value: employee.id,
    label: employee.name
  }));

  const roleOptions = selectdate.map(role => ({
    value: role.id,
    label: role.name
  }));

  const onSubmit = async (values, { setFieldValue }) => { // Destructure setFieldValue here
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const formattedStartDate = values.startDate.toISOString().split('T')[0];
      const selectedEmployeeIds = selectedEmployees.map(employee => employee.value);
      const formattedDeadline = values.deadline.toISOString().split('T')[0];

      const transformedValues = {
        'name': {
          'en': values.nameEn,
          'ar': values.nameAr
        },
        'start_date': formattedStartDate,
        'num_day': values.numDay,
        'num_hour': values.numHour,
        'deadline': formattedDeadline,
        'image': values.image,
        'status': values.status,
        'employees': selectedEmployeeIds.map(id => ({
          'id': id,
          'role': selectedRole ? selectedRole.value : '' // Use selectedRole value
        }))
      };

      const transformedValuesJSON = JSON.stringify(transformedValues);

      const response = await axios.post(
        'https://api.bokaramstore.com/api/projects/create',
        transformedValuesJSON,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response?.data.message === 'success') {
        toast.success("تم إضافة المهمة بنجاح");
      } else {
        toast.error("فشل في إرسال البيانات");
      }
    } catch (error) {
      console.error('Failed to send data:', error);
      toast.error("فشل في إرسال البيانات");
    } finally {
      setLoading(false);
    }
  };

  // console.log(selectdate);
  return (
    <div className='container mx-4'>
      <Formik
        initialValues={{ nameAr: '', nameEn: '', startDate: null, numDay: '', numHour: '', deadline: null, status: '' }}                // validationSchema={validationSchema}
        onSubmit={onSubmit}

      >
        {({ setFieldValue, values, errors, touched }) => (
          <FormikForm>
            <div className="row">
              <div className='col-4'>
                <label className='fw-bold mt-3' htmlFor="nameAr">الاسم بالعربي</label>
                <Field
                  className='form-control w-75 border-input border border-black p-1 mt-2'
                  type="text"
                  id="nameAr"
                  name="nameAr"
                  placeholder="الاسم بالعربي"
                />
                <ErrorMessage name="nameAr" component="div" className="text-danger" />
              </div>
              <div className='col-4'>
                <label className='fw-bold mt-3' htmlFor="nameEn">الاسم بالانجليزي</label>
                <Field
                  className='form-control w-75 border-input border border-black p-1 mt-2'
                  type="text"
                  id="nameEn"
                  name="nameEn"
                  placeholder="الاسم بالانجليزي"
                />
                <ErrorMessage name="nameEn" component="div" className="text-danger" />
              </div>
              <div className='col-4'>
                <label className='fw-bold mt-3' htmlFor="startDate">تاريخ البدء</label>
                <div className="d-flex align-items-center"> {/* Wrap both date picker and input field in a flex container */}
                  <ReactDatePicker
                    className='form-control w-75 border-input border border-black p-1 mt-2'
                    selected={values.startDate}
                    onChange={(date) => setFieldValue('startDate', date)}
                    dateFormat="yyyy-MM-dd"
                  />

                </div>
                <ErrorMessage name="startDate" component="div" className="text-danger" />
              </div>
              <div className='col-4'>
                <label className='fw-bold mt-3' htmlFor="numDay">عدد الأيام</label>
                <Field
                  className='form-control w-75 border-input border border-black p-1 mt-2'
                  type="number"
                  id="numDay"
                  name="numDay"
                  placeholder="عدد الأيام"
                />
                <ErrorMessage name="numDay" component="div" className="text-danger" />
              </div>
              <div className='col-4'>
                <label className='fw-bold mt-3' htmlFor="numHour">عدد الساعات</label>
                <Field
                  className='form-control w-75 border-input border border-black p-1 mt-2'
                  type="number"
                  id="numHour"
                  name="numHour"
                  placeholder="عدد الساعات"
                />
                <ErrorMessage name="numHour" component="div" className="text-danger" />
              </div>
              <div className='col-4'>
                <label className='fw-bold mt-3' htmlFor="deadline">الموعد النهائي</label>
                <ReactDatePicker
                  className='form-control w-75 border-input border border-black p-1 mt-2'
                  selected={values.deadline}
                  onChange={(date) => setFieldValue('deadline', date)}
                  dateFormat="yyyy-MM-dd"
                />
                <ErrorMessage name="deadline" component="div" className="text-danger" />
              </div>
              <div className='col-4'>
                <label className='fw-bold mt-3' htmlFor="status">الحالة</label>
                <Field
                  className='form-control w-75 border-input border border-black p-1 mt-2'
                  as="select" // استخدم <select> بدلاً من <input>
                  id="status"
                  name="status"
                >
                  <option value="pending">قيد الانتظار</option>
                  <option value="current">حالي</option>
                  <option value="complete">مكتمل</option>
                  <option value="planning">تخطيط</option>
                </Field>
                <ErrorMessage name="status" component="div" className="text-danger" />
              </div>




              <div className="col-4">
  <div className="mt-4">
    <label htmlFor="role">الصلاحية</label>
    <Select
                  value={selectedRole}
                  onChange={handleRoleChangee}
                  options={roleOptions}
                  className='w-75 mt-2'
                  isMulti
                  classNamePrefix='select'
                  placeholder="اختر الصلاحية"
                />
    <ErrorMessage name="roles" component="div" className="text-danger" />
  </div>
</div>




              <div className="mt-4 col-4">
                <label htmlFor="">اسم الموظف</label>
                <Select
                  value={selectedEmployees}
                  onChange={handleSelectChangee}
                  options={employeeOptions}
                  isMulti // تفعيل اختيار العناصر المتعددة
                  className='w-75 mt-2'
                  classNamePrefix='select'
                  placeholder="اختر الموظفين"
                />
              </div>

              <div>


                {loading && <p>Loading...</p>}
              </div>

            </div>
            <div className="w-100 mt-4">
              <Button className='col-1 btn bg-transparent' disabled={loading}>
                {loading ? <Oval
                  visible={true}
                  height="80"
                  width="80"
                  color="#4fa94d"
                  ariaLabel="oval-loading"
                  wrapperStyle={{}}
                  wrapperClass=""
                /> : <button type="submit" variant="primary" className='btn text-white bg-color px-4'>ارسال</button>}
              </Button>
            </div>
          </FormikForm>
        )}
      </Formik>
    </div>
  );
}
