import React, { createContext, useState, useContext } from 'react';

// إنشاء context
export const LanguageContext = createContext();

// تخزين لغة الافتراضية
const defaultLanguage = 'en';

// إنشاء مكون مزود
export const LanguageProvider = ({ children }) => {
  const [language, setLanguage] = useState(defaultLanguage);
const [device_token, setdevice_token] = useState([])


  // دالة لتغيير اللغة
  const changeLanguage = (newLanguage) => {
    setLanguage(newLanguage);
  };


  

  

  return (
    <LanguageContext.Provider value={{ language, changeLanguage,device_token }}>
  {children}
    </LanguageContext.Provider>
  );
};

// استخدام السياق في مكونات التطبيق
export const useLanguage = () => useContext(LanguageContext);
