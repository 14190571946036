import React from 'react'

export default function Sidnar() {
  return<>
  <div className='container-fluid'>
    <div className="row">
 
        <div className="col-6">
            <div className="text-end mx-5">
                <img src="https://bokaramstore.com/pm/uploads/site_setting/KRtz.jpg" className='h-60 pt-2       ' alt="" />
            </div>
            
        </div>
             
        <div className="col-6 d-flex justify-content-end">

          <div className="d-flex justify-content-between w-75">
 


          <div className='hoverli mt-2'>

<img src="https://bokaramstore.com/pm/uploads/site_setting/egypt.png" alt="" />
</div>

<div className='d-center hoverli'>
<p><i className="fa-solid fa-bell"></i></p>
<p className='bg-pell mx-2'>525</p>
</div>


<div className='hoverli mt-2'>
<i className="fa-solid fa-message"></i>
</div>

<div className='d-flex hoverli'>
<p className='mt-2'> Ahmed  12:392024-03-17</p>
<img src="https://bokaramstore.com/pm/uploads/site_setting/admin_panel/avatar.png"className='imgnavbar mx-2' alt="" />

</div>

          </div>


        </div>
    </div>
  </div>
  
  
  </>
}
