import React, { useState } from 'react';
import axios from 'axios';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

export default function DeleteTask({ id, onDelete }) {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      if (!token) {
        throw new Error('No token found');
      }

      const response = await axios.delete(`https://api.bokaramstore.com/api/projects/delete/${id}`, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      if (response.data.message === 'success') {
        toast.success("تم حذف المهمة بنجاح");
        if (onDelete) {
          onDelete(id);
        }
      } else {
        toast.error("فشل في حذف المهمة");
      }
    } catch (error) {
      console.error('Failed to delete task:', error);
      toast.error("فشل في حذف المهمة");
    } finally {
      setLoading(false);
    }
  };

  return (
    <button
      type="submit"
      className="btn btn-danger text-white px-4 btn-sm"
      onClick={handleDelete}
      disabled={loading}
    >
      {loading ? (
        <Oval
          height={20}
          width={20}
          color="#fff"
          visible={true}
          ariaLabel="oval-loading"
          secondaryColor="#fff"
          strokeWidth={2}
          strokeWidthSecondary={2}
        />
      ) : (
        'نعم'
      )}
    </button>
  );
}
