
import axios from "axios";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const { createContext, useState, useEffect } = require("react");

export const MyContext = createContext();


let url = "https://api.bokaramstore.com/"

export const MyContextProvider = ({ children }) => {
    const [value, setValue] = useState('Hello from context!');
    const [device_token, setdevice_token] = useState([])
    const [setRecords, setsetRecords] = useState([])

    const firebaseConfig = {
      apiKey: "AIzaSyB1FHYC1PnJpnQ_YS-8R3o6ulBNlJD13Ak",
      authDomain: "haha-10bcc.firebaseapp.com",
      projectId: "haha-10bcc",
      storageBucket: "haha-10bcc.appspot.com",
      messagingSenderId: "257470577005",
      appId: "1:257470577005:web:e6e13270a13064c9368264",
      measurementId: "G-Y6RWCJ4WGR"
    
    };
  



    useEffect(() => {
      const token = localStorage.getItem('token');
      axios.get('https://api.bokaramstore.com/api/projects/pending', {
          headers: {
              Authorization: `Bearer ${token}`
          }
      })
      .then(response => {
        setsetRecords(response.data?.data);
          // console.log("Ahmmedddd" , response.data?.data);
      })
      .catch(error => {
          console.error('Error fetching data:', error);
      });
  }, []);
  // console.log(setRecords.length);

      // Initialize Firebase
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
     
      const getrteToken = async () => {
       const permission = await Notification.requestPermission();
       // console.log(permission);
       if (permission === "granted") {
         const token = await getToken(messaging, {
           vapidKey: "BFmiMH6tZv4A7JzfusKgYTAVaO8gKlzsTcWWzvBHTgIcHvAlgScHndKbwtohFC_8_BACL-B4jSgIVJ7NbGjg_h4"
         });
        //  console.log("ahmed",token);
        setdevice_token(token);
        //  console.log(token );
        //  setToken(token)
         // setToken()
       }
     }
         // console.log(Token );
     
     getrteToken()
   

  
    return (
      <MyContext.Provider value={{ value, setValue , url,device_token,setRecords }}>
        {children}
      </MyContext.Provider>
    );
  };