import React, { useState } from 'react';
import axios from 'axios';
import { useMutation, useQueryClient } from 'react-query'; // Ensure this import is correct
import { Oval } from 'react-loader-spinner';
import toast from 'react-hot-toast';

const addComment = async ({ projectTaskId, comment }) => {
  const token = localStorage.getItem('token');
  const response = await axios.post(
    `https://api.bokaramstore.com/api/tasks-comment/create`, // Adjust URL if necessary
    { project_task_id: projectTaskId, comment },
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  console.log(response.data.message);
  if(response.data.message === 'success'){
    toast(response.data.message)
  }
  return response.data;
};

export default function AddComment({ id }) {
  const [comment, setComment] = useState('');
  const queryClient = useQueryClient();

  const mutation = useMutation(addComment, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['comments', id]);
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate({ projectTaskId: id, comment });
    setComment(''); // Clear the input field
  };

  return (
    <div className="container">
      <form onSubmit={handleSubmit}>
        <div className="mb-3 mx-auto d-center flex-column">
          <label htmlFor="comment" className="form-label fw-bold w-75 mt-5">
            إضافة تعليق
          </label>
          <textarea
            type="text"
            className="form-control w-75 mx-auto mt-2 border border-black"
            placeholder='اضافة تعليق'
            id="comment"
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="btn border-0 mx-5" disabled={mutation.isLoading} >
          {mutation.isLoading ? <>
          
          
            <Oval
  visible={true}
  height="80"
  width="80"
  color="#4fa94d"
  ariaLabel="oval-loading"
  wrapperStyle={{}}
  wrapperClass=""
  />
          </> : <div className='mx-4'><button className='btn btn-primary mx-5 px-4'>ارسال</button></div> }
        </button>
        {mutation.isError && <p className="text-danger">حدث خطأ أثناء إرسال التعليق.</p>}
      </form>
    </div>
  );
}
