import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import Ubdateuser from './Ubdateuser';
import { Link } from 'react-router-dom';
import ModelObject from './modelObject';
import Updatemodel from './UpdateModel';
import DeleteTask from './DeleteTask';

export default function Datetable() {
    const [records, setRecords] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedUserId, setSelectedUserId] = useState(null);

    const handleClose = () => setShowModal(false);
    const handleShow = () => setShowModal(true);
    
    const [sho, setSho] = useState(false);
    const [sh, setSh] = useState(false);
    const [selectedId, setSelectedId] = useState(null); // الـ ID المحدد
    
    const handleClos = () => {
        setSho(false);
        setSelectedId(null); // إعادة تعيين الـ ID عند إغلاق النافذة
    };
    
    const handleSho = (id) => {
        setSho(true);
        setSelectedId(id); // تعيين الـ ID عند النقر على الزر
    };
    
    const handleClo = () => {
        setSh(false);
        setSelectedId(null); // إعادة تعيين الـ ID عند إغلاق النافذة
    };
    
    const handleSh = (id) => {
        setSh(true);
        setSelectedId(id); // تعيين الـ ID عند النقر على الزر
    };

    const columns = [
        {
            name: "الأسم",
            selector: (row) => <Link className='d-center' to={`/AddTaskProject/${row.id}`}>{row?.name}</Link>,
            sortable: false,
        },
        {
            name: "  الكود ",
            selector: (row) => row.number,
            sortable: true,
        },
        {
            name: " وقت البدايه ",
            selector: (row) => row.start_date,
            sortable: true,
        },
        {
            name: " عدد الايام",
            selector: (row) => row.num_day,
            sortable: true,
        },
        {
            name: " عدد الساعات",
            selector: (row) => row.num_hour,
            sortable: true,
        },
        // {
        //     name: " تحديث",
        //     selector: (row) => row.updated_at?.slice(0, 10),
        //     sortable: true,
        // },
        {
            name: "  موعد التسليم",
            selector: (row) => row.deadline?.slice(0, 10),
            sortable: true,
        },

        {
          name: "الحاله",
          cell: (row) => (
              <div className="w-75 ">
                  {row.status === "pending" ? 
                      <div className='bg-color text-center text-white border rounded p-1'>قيد الانتظار</div> :
                      row.status === "current" ? 
                      <div className='bg-color text-center text-white border rounded p-1'>قيد العمل</div> :
                      row.status === "complete" ?
                      <div className='bg-color text-center text-white border rounded p-1'>مكتمل</div> :
                      row.status === "planing" &&
                      <div className='bg-color text-center text-white border rounded p-1'>مستقبلياً</div>
                  }
              </div>
          ),
          sortable: true,
          selector: (row) => row.Processess,
      },
      {
        name: "   التعاملات",
        selector: (row) => <div className='d-flex'>
    
    <button type="button" class="btn btn-warning text-white px-2 btn-sm" onClick={() => handleSho(row.id)}><i class="fas fa-edit"></i></button>
<div className='px-2'>
<button type="button" class="btn btn-danger text-white px-2 btn-sm" onClick={() => handleSh(row.id)}><i class="fa fa-trash" aria-hidden="true"></i></button></div>
    </div>,
        sortable: true,
    },
    ];
    useEffect(() => {
        const token = localStorage.getItem('token');
        axios.get('https://api.bokaramstore.com/api/projects/pending', {
            headers: {
                Authorization: `Bearer ${token}`
            }
        })
        .then(response => {
            setRecords(response.data?.data);
            console.log("Ahmmedddd" , response.data?.data);
        })
        .catch(error => {
            console.error('Error fetching data:', error);
        });
    }, []);



    useEffect(() => {
        const handleDelete = (row) => {
            const token = localStorage.getItem('token');
            const id = row.id;
            axios.delete(`https://api.bokaramstore.com/api/employees/delete/${id}`, {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            })
            .then(response => {
                axios.get('https://api.bokaramstore.com/api/employees', {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then(response => {
                    setRecords(response.data.data);
                })
                .catch(error => {
                    console.error('Error fetching data:', error);
                });
            })
            .catch(error => {
                console.error('Error deleting data:', error);
            });
        };
    }, [records])
    


 

    const handleRowClick = (id) => {
        setSelectedUserId(id);
        handleShow();
    };
    
    return (
        <>
            <Link className="fw-bold text-dark" to={"/"}>
                الرئيسية
            </Link>
            <Link className="text-muted mx-2 text-decoration-none mx-4">
                فريق العمل
            </Link>

            <div className="mt-5 d-flex">
                <p className="mx-2">
                    <i className="fa fa-cog" aria-hidden="true"></i>
                </p>
                <p className="text-muted">فريق العمل</p>
            </div>

            <div className="mt-4 d-flex">
                <button
                    className="btn bg-color p-1 text-white mx-3"
                    onClick={handleShow}
                >
                    <i className="fa fa-plus"></i> اضافة
                </button>
            </div>

            <DataTable
                columns={columns}
                data={records}
                selectableRows
                pagination
            />

            <Modal show={showModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title className='pt-4 mx-4 pb-4'>   اضافة مشروع جديد  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <ModelObject></ModelObject>
                    {/* <Ubdateuser userId={selectedUserId} /> */}
                </Modal.Body>
                <Modal.Footer className='mx-4 mb-3'>
                    <Button variant="secondary" className='px-3' onClick={handleClose}>
                        اغلاق
                    </Button>
                </Modal.Footer>
            </Modal>



 
<Modal show={sho} onHide={handleClos}>
    <Modal.Header closeButton className='mx-4 mt-4'>
        <Modal.Title>تعديل المهمه</Modal.Title>
    </Modal.Header>
    <Modal.Body className='mx-4 mt-4 mb-4'>
        {/* يمكنك إرسال selectedId إلى Updatemodel */}
        <Updatemodel id={selectedId} />
    </Modal.Body>
    <Modal.Footer className='mx-4 text-end pb-2 pt-3'>
        <Button variant="secondary px-3 " onClick={handleClos}>
            Close
        </Button>
    </Modal.Footer>
</Modal>

 
<Modal show={sh} size='sm' onHide={handleClo}>
    <Modal.Header closeButton className='mx-4 mt-4'>
        <Modal.Title>حذف المهمه</Modal.Title>
    </Modal.Header>
    <Modal.Body className='mx-4 mt-4 mb-4 d-flex justify-content-between w-50 mx-auto'>
        {/* يمكنك إرسال selectedId إلى Updatemodel */}
        <DeleteTask id={selectedId} />
        <Button variant="secondary px-4 " onClick={handleClo}>
            لا
        </Button>
    </Modal.Body>
    <Modal.Footer className='mx-4 text-end pb-2 pt-3'>

    </Modal.Footer>
</Modal>

        </>
    );
}
