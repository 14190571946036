import axios from 'axios';
import React, { useState, useEffect } from 'react';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

export default function Waitingcapacityy({ id }) {
  const [status, setStatus] = useState("2");
  const [isLoading, setIsLoading] = useState(false); // حالة لتتبع التحميل
  const [isSent, setIsSent] = useState(false); // حالة لتتبع إرسال البيانات

  const handleStatusChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSendStatus = async () => {
    try {
      setIsLoading(true); // تعيين isLoading إلى true عند بدء الطلب
      const token = localStorage.getItem('token');
      await axios.post(
        `https://api.bokaramstore.com/api/projects-task/update/status/${id}`,
        { status },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );
      setIsSent(true); // تعيين isSent إلى true عند نجاح الطلب
      toast.success('تم الإرسال بنجاح');
    } catch (error) {
      toast.error('فشل في الإرسال');
      throw new Error('فشل في جلب البيانات');
    } finally {
      setIsLoading(false); // تعيين isLoading إلى false بعد الانتهاء من الطلب
    }
  };

  useEffect(() => {
    if (isSent) {
      // إعادة تعيين isSent إلى false بعد فترة قصيرة
      const timer = setTimeout(() => {
        setIsSent(false);
      }, 5000); // 5 ثواني

      // تنظيف المؤقت عند الغاء التأثير
      return () => clearTimeout(timer);
    }
  }, [isSent]);

  return (
    <div className='w-75 mx-auto mt-5 mb-5'>
      <div className="row">
        <div className="col-4">  
          <div className="form-check w-50">
            <input
              className="form-check-input border-black"
              type="radio"
              name="exampleRadios"
              disabled
              id="exampleRadios3"
              value="pending"
              checked={status === "pending"}
              onChange={handleStatusChange}
            />
            <label className="form-check-label w-75" htmlFor="exampleRadios3">
              قيد الانتظار
            </label>
          </div>
        </div>
        <div className="col-4">
          <div className="form-check w-50">
            <input
              className="form-check-input border-black font-20"
              type="radio"
              name="exampleRadios"
              id="exampleRadios1"
              value="current"
              checked={status === "current"}
              onChange={handleStatusChange}
            />
            <label className="form-check-label w-75 fw-bold " htmlFor="exampleRadios1">
              قيد العمل
            </label>
          </div>
        </div>
        <div className="col-4">  
          <div className="form-check w-75">
            <input
              className="form-check-input border-black"
              type="radio"
              name="exampleRadios"
              id="exampleRadios2"
              disabled
              value="completed"
              checked={status === "completed"}
              onChange={handleStatusChange}
            />
            <label className="form-check-label w-75" htmlFor="exampleRadios2">
              تم انتهاء المهمة
            </label>
          </div>
        </div>
      </div>
      {/* عرض زر التحميل أثناء التحميل */}
      {isLoading && <button className="btn border-0 mt-3" disabled>
      <Oval
  visible={true}
  height="80"
  width="80"
  color="#4fa94d"
  ariaLabel="oval-loading"
  wrapperStyle={{}}
  wrapperClass=""
  /></button>}
      {/* عرض زر الإرسال عند عدم التحميل والإرسال */}
      {!isLoading && !isSent && <button className="btn btn-primary mt-3 px-3" onClick={handleSendStatus}>إرسال</button>}
      {/* عرض رسالة النجاح بعد الإرسال */}
      {isSent && <p className="mt-3">تم الإرسال بنجاح!</p>}
    </div>
  );
}
