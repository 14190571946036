import axios from 'axios';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import toast from 'react-hot-toast';
import { Oval } from 'react-loader-spinner';

export default function DeleteTask({ id, onDeleteSuccess }) {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);
    const token = localStorage.getItem('token');
    try {
      let data = await axios.post(`https://api.bokaramstore.com/api/projects-task/delete/${id}`, {
        _method: "delete"
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log(data.data.message);
      if (data.data.message === "success") {
        toast.success(data.data.message);
        setLoading(false);
        onDeleteSuccess(); // Close the modal
      }
    } catch (error) {
      console.error('Error deleting task:', error);
      setLoading(false);
    }
  };

  return (
    <div className="container">
      <h4 className='mb-3 mt-3 mx-4'>هل متأكد من مسح المهمه</h4>
      <Button variant="btn" className='px-5 border-0' onClick={handleDelete} disabled={loading}>
        {loading ? (
          <button className='btn border-0'>
            <Oval
              visible={true}
              height="80"
              width="80"
              color="#4fa94d"
              ariaLabel="oval-loading"
              wrapperStyle={{}}
              wrapperClass=""
            />
          </button>
        ) : (
          <button className='btn-danger btn px-4'>نعم</button>
        )}
      </Button>
    </div>
  );
}
